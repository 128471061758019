@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import 'tailwindcss/tailwind.css'; */

/* @font-face {
  font-family: '--general-sans-bold';
  src: url('../src/assests/about/font/general-sans/GeneralSans-Bold.woff2')  format('woff2');
} */

@font-face {
  font-family: "--general-sans-bold";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Bold.woff2")
    format("woff2");
}
@font-face {
  font-family: "--general-sans-semibold";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Semibold.woff2")
    format("woff2");
}

@font-face {
  font-family: "--general-sans-regular";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Regular.woff2")
    format("woff2");
}
@font-face {
  font-family: "--general-sans-medium";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "--general-sans-extralight";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Extralight.woff2")
    format("woff2");
}

@font-face {
  font-family: "--general-sans-light";
  src: url("../../newassets/about/font/general-sans/GeneralSans-Light.woff")
    format("woff2");
}

body {
  font-family: Epilogue !;
}
button {
  font-family: "general sans " !important;
}
.nav-body {
  font-family: "General Sans", sans-serif !important;
}
nav-link {
  font-family: "General Sans", sans-serif !important;
}
.button {
  margin-left: 10px;
}

.App {
  text-align: center;
}

.nav-container {
  margin-bottom: 200px !important;
}

.about-company {
  text-align: center !important;
}
.about-company h2 {
  text-align: center !important;
  font-size: 62px !important;
  font-weight: 600;
  line-height: 64px;
  padding: 20px;
  font-family: Epilogue !important;
  color: #0b0a0a !important;
  margin-top: 35px !important;
}
.about-company p {
  text-align: center;
  font-size: 22px;
  color: #4f4f4f;
  line-height: 35px;
  font-family: General sans !important;
  padding: 10px;
}
.text {
  margin-right: 7px;
}
.custom-col-width {
  flex: 0 0 65.16% !important;
  max-width: 57.16%;
}

.text h2 {
  font-size: 56px !important;
  font-weight: 600;
  line-height: 67px !important;
  font-family: Epilogue !important;
  color: #0b0a0a !important;
}
.text p {
  font-family: General Sans !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.01em;
  width: 490px;
}
.text .slide {
  color: #2f9d92 !important;
}
.content-container {
  max-width: 766px;
  text-align: center;
  font-weight: 500;
}
.content-container .grey-btn {
  border: 1px solid #2f9d92;
}
.trusted-by {
  margin-top: 100px;
  background-color: none !important;
}
.trusted-by h2 {
  color: #0b0a0a !important;
  font-family: Epilogue !important;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
}
.active {
  background-color: none !important;
}
.track {
  font-size: 30px !important;
  color: #0b0a0a !important;
  font-family: Epilogue !important;
  font-weight: 600 !important;
  line-height: 48px;
  text-align: center;
}
footer {
  color: #4f4f4f !important;

  font-family: General sans !important;
}
.footer h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #0b0a0a;
  font-size: 18px;

  font-family: General sans !important;
}

.footer .container {
  text-align: center;
}
.privacy {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}
.privacy span {
  font-family: General sans !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.footer p,
li {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #4f4f4f;
}
.footer-links {
  margin: 0 !important;
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 500;
  color: #4f4f4f !important;
  text-decoration: none !important;
}
li {
  cursor: pointer;
}
.footer a {
  color: #000;
  text-decoration: none;
}
.footer span {
  font-family: General sans !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.footer a:hover {
  text-decoration: underline;
}
.atext {
  width: inherit;
  height: 50vh;
}

.green-btn {
  background: #2f9d92 !important;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  color: #ffffff;
  font-family: General sans !important;
  font-weight: 600;
}
a-text:active {
  color: white;
}
/* .tracking-text {
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  width: 50%;
  justify-content: center;
} */
.grey-btn {
  color: #2f9d92;
  background: #f4f4f4;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  font-family: General sans;
}
.grey-btn:hover {
  border: 1px solid #2f9d92;
}
.green-btn:hover {
  border: 2px solid #f4f4f4;
}
.f-c {
  background: #f4fbfa;
}

.image2 {
  width: 100%;
  margin-top: 20px;
}

.leftSide {
  background-image: url("../../newassets/background.svg");
}
.atext {
  position: relative;
  height: 60% !important;
  width: 100%;
}
/* .hero-section {
  padding: 1rem 5rem 0 5rem;
} */

.accd {
  /* background-image: url("./assests/aboutpage-vector.svg"); */
  background-image: url("../../newassets/aboutpage-vector.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 1);
}
.merge p {
  margin-left: auto;
}
.merge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 200px !important;
  align-items: center;

  /* justify-content: space-between;
  align-items: center;
  margin: auto; */
}
.m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.animation {
  padding: 20px 0;
}

.animated-text {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.social-icon {
  margin-right: 10px;
}

.copyright-text {
  /* font-family: "general sans", sans-serif; */
  font-family: General sans;
  color: #2a2a2a;
  margin-top: 10px;
}
.copyw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}
@media (max-width: 768px) {
  .footer-container {
    text-align: left;
  }

  .social-icons {
    justify-content: flex-start;
  }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  justify-content: center;
}

/* Media Query for small screens */
@media (max-width: 900px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .image2 {
    display: none;
  }
  .f-c {
    background: #ddf3f1;
  }
  .text {
    margin-right: 7px;
  }
  .landing {
    padding: 0 2rem 0 2rem !important;
  }
}
@media (max-width: 450px) {
  .f-c {
    background: #f4fbfa;
  }
  .custom-col-width {
    max-width: 100%;
    padding: 0 !important;
  }

  .text h2 {
    font-size: 40px !important;
  }
  .landing {
    padding: 0 1.2rem 0 1.2rem !important;
  }
  .text p {
    width: 320px;
  }
  .merge p {
    margin-left: 0;
    padding: 0;
  }
  .merge {
    gap: 10px !important;
  }

  .animation-p {
    font-size: 30px !important;
    text-align: center;
  }
  .social-icons {
    display: none;
  }
  .social-icon-small {
    display: flex;
    justify-content: space-between !important;
  }
  .about-company p {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }
  .about-company h2 {
    font-size: 50px !important;
  }
  .randomText {
    margin-top: 20px !important;
  }
  .randomtextfont {
    font-size: 45px !important;
  }
  .sign-button {
    display: flex;
    flex-direction: row;
  }
  .lst {
    margin-top: 20px;
  }

  .footer-prembly {
    display: flex;
    justify-content: center;
    background-color: red;
  }
  .ft-links {
    display: none !important;
  }
  .privacy {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 451px) {
  .privacy {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 450px) {
  .social-icon-small {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
  }
}

@media (min-width: 450px) {
  .social-icon-small {
    display: none !important;
    justify-content: space-between !important;
  }
  .ft-link {
    display: none !important;
  }
  .merge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;

    /* justify-content: space-between;
    align-items: center;
    margin: auto; */
  }
  .m {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (max-width: 750px) {
  .about-company p {
    text-align: center;
    font-size: 18px;
    color: #4f4f4f;
    line-height: 35px;
    font-family: General sans !important;
    padding: 10px;
  }
}
.icon-blog::before {
  content: "\f02d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-research::before {
  content: "\f02d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-career::before {
  content: "\f02d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.icon-events::before {
  content: "\f02d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.animated-text:nth-child(1) {
  animation-delay: 0s;
}

.animated-text:nth-child(2) {
  animation-delay: 2.5s;
}

.animated-text:nth-child(3) {
  animation-delay: 5s;
}

.animated-text:nth-child(4) {
  animation-delay: 7.5s;
}

@keyframes fadeInOut {
  0%,
  25%,
  100% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
}
/* .merge p{
  margin-right: 500px;
} */
.merge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;

  /* justify-content: space-between;
  align-items: center;
  margin: auto; */
}
.m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* General styles for the animated text */
.animated-text {
  position: absolute;
  opacity: 0;
  animation: fadeInOut 10s linear infinite;
  font-size: 2.5rem;
  color: #1d1d1d;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  font-family: "General Sans", sans-serif;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  font-size: 53.48px;
  height: 100%;
}
.animation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 200px;
}
.animation h1 {
  position: relative;
  width: 100%;
  text-align: center;
  margin-left: -170px;
}
.animation span {
  font-family: Epilogue;
  font-size: 60px;
  font-weight: 600;
  line-height: 55.39px;
  text-align: center;
}

.navbar-nav .dropdown-menu {
  margin-top: 100px;
}
.link {
  text-decoration: none;
  color: #0b0a0a;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: fit-content;
}
.link p {
  color: #677489;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.social-img:hover {
  border: 1px solid black;
  border-radius: 8px;
}

.bg-container {
  width: 100%;
  height: 100vh; /* Adjust as needed */
  background-image: url("../../newassets/Header\ Image.svg");
  background-size: cover;
  background-position: center;
  height: 500px;
}

@media (max-width: 576px) {
  .bg-container {
    background-image: url("../../newassets/headerMobile.svg");
    height: 500px;
  }
}

.desktop_hero_bolt {
  /* background-image: url('../src//assests/about/aboutdesktopbanner.svg'),  */
  background-image: url("../../newassets/Header\ Image.svg");
  background-size: cover;
  background-blend-mode: overlay;
  height: 600px;
}
.desktop_hero_about {
  /* background-image: url('../src//assests/about/aboutdesktopbanner.svg'),  */
  background-image: url("../../newassets/about/aboutdesktopbanner.svg"),
    linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-size: cover;
  background-blend-mode: overlay;
}
.desktop_hero_story {
  /* background-image: url('../src//assests/about/aboutdesktopbanner.svg'),  */
  background-image: url("../../newassets/storyHeader.svg"),
    linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-size: cover;
  background-blend-mode: overlay;
  height: 600px;
}

/* accordion.css */
.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}

.accordion-header {
  padding: 1.25rem 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  cursor: pointer;
}

.accordion-content {
  overflow: hidden;
  transition: max-width 0.3s ease-out;
  max-width: 0;
}

.accordion-content.open {
  max-width: 100%;
  transition: max-width 0.3s ease-in;
}

.accordion-content-inner {
  padding: 1rem 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  background-color: #ffffff;
  margin-top: 1rem;
}

.accordion-content-inner h2 {
  font-family: "epi", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.accordion-content-inner p {
  font-family: "generalSansLight", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #353535;
  line-height: 2rem;
}

/* CSS for animation */
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Apply animation to the element */
.animate-slide {
  animation: slide-in 1s ease forwards;
}

/* Apply animation when closing or toggling */
.animate-slide.animate-slide-out {
  animation: slide-out 1s ease forwards;
}

/* CSS for animation */
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10%);
  }
}

/* Apply animation to the element */
.animate-slide {
  animation: slide-in 1s ease forwards;
}

/* Apply animation when closing or toggling */
.animate-slide.animate-slide-out {
  animation: slide-out 1s ease forwards;
}

.name-slider {
  transition: transform 0.3s ease-in-out; /* Example transition property */
}
.name-slider {
  transition: opacity 0.3s ease-in-out; /* Example transition property */
}

.fadeIn {
  animation: fadeIn 1s forwards;
}

.fadeOut {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.white-btn {
  background-color: #ffffff;
  color: #2f9d92;
  border: none;
  border-radius: 8px;
  margin-top: 10px !important;
  padding: 16px 30px;
  font-weight: 600;
  font-family: General sans;
}

.accordion-item {
  position: relative;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  margin-bottom: 1.4rem;
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.accordion-item.active .accordion-header {
  color: white; /* Adjust text color for active item */
}

.accordion-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red; /* Adjust background color for active item */
  z-index: -1;
  transform: translateX(-100%);
  transition: transform 0.3s ease; /* Smooth sliding transition */
}

.accordion-item.active .accordion-slider {
  transform: translateX(0); /* Slide to the active item */
}
.accordion-item {
  margin-bottom: 1.4rem;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-header.active {
  background-color: #2f9d92; /* Active background color */
  color: white; /* Active text color */
}

.accordion-content {
  padding: 1rem 2rem;
  border: 1px solid #e6e6e6;
  border-top: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation-in {
  animation: fadeIn 5s forwards;
}

.animation-out {
  animation: fadeOut 10s forwards;
}

.animation-p {
  position: absolute;
  transition: top 0.5s, left 0.5s;
}

.custom-header {
  font-family: "Epilogue", sans-serif;
  font-size: 78px;
  font-weight: 600;
  line-height: 82px;
  text-align: center;
  color: #ffffff;
}
.mergerText {
  font-family: General Sans;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: -20px;
}

.mergerText h5 {
  color: #4f4f4f;
  font-weight: 500;
}
.mergeText a {
  font-weight: 600;
  text-decoration: underline !important;
}
.mergeIcon {
  display: inline-block;
  font-size: 22px;
}
/* .bolttext {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
} */

/* .whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 10px;
  width: 40px;
  height: 40px;
} */

.whatsapp-float {
  position: fixed;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  /* background-color: #2F9D92; */
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 18px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 1px 16px;
  text-decoration: none;
}

.whatsapp-content {
  display: flex;
  align-items: center;
}

.whatsapp-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.whatsapp-text {
  color: #0b0a0a;
  font-size: 18px;
  font-family: General Sans !important;
}
@media screen and (max-width: 500px) {
  .custom-header {
    font-family: "Epilogue", sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
  }
  .storyText {
    font-family: General Sans !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 35px !important ;
    text-align: center !important;
    max-width: 100% !important;
  }
  .smallText {
    font-size: 30px;
    font-weight: 500;
  }
  .img-parent {
    margin-top: 33px;
  }
  .desktop_hero_story {
    /* background-image: url('../src//assests/about/aboutdesktopbanner.svg'),  */
    background-image: url("../../newassets/storyheader.png"),
      linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-size: cover;
    height: 60vh !important;
  }
  .st {
    margin-top: -20px;
  }
  .merge {
    font-size: 14px;
  }
  .mergeIcon {
    display: inline-block;
    font-size: 50px;
  }
}
