@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import 'tailwindcss/tailwind.css'; */
:root {
  --marquee-width: 90vw;
  --marquee-height: 30vh;
  --marquee-elements: 7;
  --marquee-elements-displayed: 4;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
  max-width: 100vw;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  position: relative;
}
@font-face {
  font-family: GT-black;
  src: url("assets/fonts/GTWalsheimPro-Black.ttf");
}
@font-face {
  font-family: GT-bold;
  src: url("assets/fonts/GTWalsheimPro-Bold.ttf");
}
@font-face {
  font-family: GT-medium;
  src: url("assets/fonts/GTWalsheimPro-Medium.ttf");
}
@font-face {
  font-family: GT-regular;
  src: url("assets/fonts/GTWalsheimPro-Regular.ttf");
}
@font-face {
  font-family: GT-light;
  src: url("assets/fonts/GTWalsheimPro-Light.ttf");
}
@font-face {
  font-family: GT-thin;
  src: url("assets/fonts/GTWalsheimPro-Thin.ttf");
}

@font-face {
  font-family: ST-blacks;
  src: url("assets/fontss/Satoshi-Black.ttf");
}

@font-face {
  font-family: ST-bolds;
  src: url("assets/fontss/Satoshi-Bold.ttf");
}

@font-face {
  font-family: ST-mediums;
  src: url("assets/fontss/Satoshi-Medium.ttf");
}

@font-face {
  font-family: ST-regulars;
  src: url("assets/fontss/Satoshi-Regular.ttf");
}

@font-face {
  font-family: ST-lights;
  src: url("assets/fontss/Satoshi-Light.ttf");
}

h1,
h2 {
  font-family: ST-blacks;
}
h3,
h4 {
  font-family: ST-bolds;
}
h5 {
  font-family: ST-mediums;
}
h6 {
  font-family: ST-regulars;
}
p,
a,
small {
  font-family: ST-regulars;
}

#partners,
#animation,
#about,
#premblyEcosystem,
#built,
#solutions,
#dev,
#how-to-get-started,
#features-areas,
#random-background {
  scroll-margin: 3rem;
}

#features-areas {
  padding: 220px 0 140px 0;
}

#whyPrembly {
  scroll-margin: 1rem !important;
}

.navbar-options {
  position: absolute;
  box-shadow: 0px 4px 12px 0px #091e4226;
  background: #faf8f8;
  border-radius: 10px;
  padding: 20px 20px;
  right: 5%;
  top: 50px;
  border: 1px solid #ddd;
  z-index: 4;
}

.imagetagborder {
  width: 60px;
  height: 60px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 7px;
  background: #ffffffb6;
}

.navbar-options .imagetagborder {
  margin-right: 20px !important;
}

.navbar-options img {
  width: 40px;
  margin-right: 25px;
}

.navbar-options .positionidpass img {
  width: 40px;
  margin-right: 15px;
}

.navbar-options .positionidpasss span h6 {
  font: 13px ST-bolds !important;
  color: #000000;
}

.navbar-options .positionidpasss img {
  width: 20px;
  margin-right: 15px;
}

.navbar-options .products {
  /* border-bottom2 1px solid #ecedef; */
  margin-bottom: 7px;
  margin-top: 10px;
  width: 100%;
}

.navbar-options .products .positionidpass {
  display: flex !important;
  padding: 13px 7px 0px 15px;
  /* align-items: center !important; */
}

.navbar-options .productSolution .positionidpass {
  display: flex;
  padding: 10px 7px 10px 15px;
}

.navbar-options .productSolution:hover {
  background-color: #c3cccf;
  border-radius: 17px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-options .productSolutions:hover {
  background-color: #c3cccf;
  border-radius: 17px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-options .products:hover,
.nav-options {
  background-color: #c3cccf;
  border-radius: 17px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-options .productSolution span p {
  font-size: 14px;
  color: #003e51;
  margin-top: 17px;
  font-family: ST-bolds;
}

.navbar-options .products span h6 {
  font-size: 14px;
  color: #000000;
  font-family: ST-bolds;
}

.navbar-options .productResource {
  width: 100%;
  margin-top: 10px;
}

table,
th,
td {
  border: 1px solid black !important;
  border-collapse: collapse;
}

.navbar-options .products-second-option {
  left: 0;
  min-width: 400px !important;
}

.navbar-options p {
  font: 13px GT-light;
  line-height: 1.3rem;
  color: #474747;
}

.navbar-options.products-options {
  left: 0;
  min-width: 400px;
}

.navbar-options.products-optiones {
  left: 0;
  min-width: 200px;
}

.navbar-options.products-option {
  left: 0;
  min-width: 340px;
}

.navbar-options.products-option {
  left: 0;
  min-width: 340px;
}

.navbar-options.products-optionss {
  margin-right: -100px;
}

.navbar-options.products-option {
  left: 0;
  min-width: 340px;
}

.navbar-options.resourceSolution {
  left: 0;
  min-width: 340px !important;
}

.navbar-options.products-option {
  left: 0;
  min-width: 680px;
}

.navbar-options.resourceSolution {
  left: 0;
  min-width: 400px !important;
}

.navbar-nav.colors.blue a.btn-sales1 {
  border: 1px solid #4312c7 !important;
  background: #fff;
  color: #4312c7 !important;
}

.allColoredCard.colorCard.blueC .feedbackCard {
  background: #f8f7fb;
  border-radius: 8px;
}

.latest.bluebg {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/blubg.png")
    center center no-repeat;
  background-size: cover;
  align-items: center;
}

.latest.bluebg .email-btn {
  background: #4312c7;
  border: 1px solid #fff !important;
}

.latest.bluebg i {
  color: #4312c7;
}

.latest.bluelightbg {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/cta.png")
    center center no-repeat;
  background-size: cover;
  align-items: center;
}

.latest.bluelightbg .email-btn {
  background: #0768dd;
  border: 1px solid #fff !important;
}

.latest.bluelightbg i {
  color: #0768dd;
}

.feedbackCard.newblue.card {
  background: "black";
}

.homelogosid {
  width: 40px !important;
  height: 40px !important;
}

.latest.pinkbg {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/pinkbg.jpg")
    center center no-repeat;
  background-size: cover;
  align-items: center;
}

.latest.pinkbg .email-btn {
  background: #bf4a86;
  border: 1px solid #fff !important;
}

.latest.pinkbg i {
  color: #bf4a86;
}

.latest.pinkbg .email-btn:hover {
  border: 1px solid #4312c7 !important;
}

.cardColor.bluelightings .card,
.cardColor.bluelighting.feedback {
  background: black !important;
  border-radius: 8px;
}

.allColoredCard.newblue {
  background: #f8f7fb !important;
}

.navbar-nav.colors.blue a.btn-sales1:hover {
  background: #07485e !important;
  color: #ffff !important;
}

.navbar-nav.colors.blue a.btn-sales2 {
  border: 1px solid #4312c7 !important;
  background: #4312c7 !important;
  color: #ffffff !important;
}

.navbar-nav.colors.blue a.btn-sales2:hover {
  background: #07485e !important;
  color: #ffff !important;
}

.navbar-nav.colors.pinkNav a.btn-sales1 {
  border: 1px solid #de00b6 !important;
  background: #fff;
  color: #de00b6 !important;
}

.navbar-nav.colors.pinkNav a.btn-sales1:hover {
  background: #07485e !important;
  color: #ffff !important;
  border: 1px solid #07485e !important;
}

.navbar-nav.colors.pinkNav a.btn-sales2 {
  border: 1px solid #de00b6 !important;
  background: #de00b6 !important;
  color: #ffffff !important;
}

.navbar-nav.colors.bluelights a.btn-sales1 {
  border: 1px solid #0768dd !important;
  background: #fff;
  color: #0768dd !important;
}

.navbar-nav.colors.bluelights a.btn-sales1:hover {
  background: #07485e !important;
  color: #ffff !important;
  border: 1px solid #07485e !important;
}

.navbar-nav.colors.bluelights a.btn-sales2 {
  border: 1px solid #0768dd !important;
  background: #0768dd !important;
  color: #ffffff !important;
}

.navbar-nav.colors.pinkNav a.btn-sales2:hover,
.navbar-nav.colors.bluelights a.btn-sales2:hover {
  background: #07485e !important;
  color: #ffff !important;
  border: 1px solid #07485e !important;
}

/* .navbar-options.products-options::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: none;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 35px solid #faf8f8;
  top: -20px;
  left: 30px;
} */

.solutions-area .rec-arrow-left,
.solutions-area .rec-arrow-right,
.loved-by-section .rec-arrow-left,
.loved-by-section .rec-arrow-right {
  display: none;
}

/* Dark mode starts here */

.app.dark-mode {
  background-color: #01070a;
}
.link {
  text-decoration: none !important;
}

.btn-green1 {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-bolds;
  justify-content: center;
  border: 1px solid #37b7ab;
  /* color: #37b7ab !important; */
}

.btn-deep-green1 {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-bolds;
  justify-content: center;
  border: 1px solid #37b7ab;
  /* color: #37b7ab !important; */
}

.btnee {
  color: #2f9d92;
  background: #f4f4f4;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-weight: 600;
  font-family: General sans;
  width: fit-content;
  margin: 20px;
}

.btn-greener {
  background-color: #56ccc1 !important;
  color: #000000 !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-regulars !important;
  justify-content: center;

  border: 1px solid #56ccc1;
  /* color: #37b7ab !important; */
}

.btn-blue {
  background-color: #4312c7 !important;
  color: #fff !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-bolds !important;
  justify-content: center;
  border: 1px solid #37b7ab;
}

.btn-bluelight {
  background-color: #0768dd !important;
  color: #fff !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-bolds !important;
  justify-content: center;
  border: 1px solid #0768dd;
}

.btn-pink {
  background-color: #de00b6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  display: flex;
  padding: 13px 50px !important;
  text-align: center;
  font-family: ST-bolds !important;
  justify-content: center;
  border: 1px solid #37b7ab;
}

.btn-pinks {
  color: #de00b6 !important;
  display: flex !important;
  align-items: center !important;
}

.btn-pinks i {
  font-size: 20px;
}
.btn-none {
  background-color: none !important;
  color: #37b7ab !important;
  border-radius: 7px !important;
  margin-left: -10px;
  display: flex !important;
  align-items: center;
}

.btn-none span {
  font: 24px GT-medium !important;
}

.btn-none i {
  color: #37b7ab !important;
  font-size: 22px !important;
}

.btn-green4 {
  background-color: transparent !important;
  color: #bcebe7 !important;
  border-radius: 4px !important;
  display: flex;
}

.btn-green2 {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 10px !important;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 1px solid #37b7ab;
}

.btn-green3 {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 15px !important;
  padding-top: 12px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  border: 1px solid #37b7ab;
}

.btn-green3-outline {
  background-color: transparent !important;
  color: #37b7ab !important;
  border-radius: 0 !important;
  border: 1px solid #37b7ab !important;
}

.btn-green6 {
  background-color: #4312c7 !important;
  color: #fff !important;
  border-radius: 13px !important;
  padding-top: 12px !important;
  height: 50px;
  display: flex;
  justify-content: center;
  border: 1px solid #4312c7;
}

.btn-green6-outline {
  background-color: transparent !important;
  color: #4312c7 !important;
  border-radius: 0 !important;
  border: 1px solid #37b7ab !important;
}

.btn-green1-outline {
  background-color: transparent !important;
  color: #37b7ab !important;
  border-radius: 0 !important;
  border: 1px solid #37b7ab !important;
}

.btn-green {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 0 !important;
  border: 1px solid #fff;
  border-radius: 15px !important;
}

.btn-white {
  background-color: #fff !important;
  color: #003241 !important;
  letter-spacing: 2px;
  font-size: 19px !important;
  border-radius: 0 !important;
  border: 1px solid #37b7ab;
}

.btn-green-outline {
  background-color: transparent !important;
  color: #37b7ab !important;
  border-radius: 0 !important;
  border: 1px solid #37b7ab !important;
}

.btn-black1 {
  background-color: none !important;
  color: #fff !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font: GT-thin !important;
}

.btn-blacklight {
  background-color: none !important;
  color: #0768dd !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-family: ST-mediums !important;
}

.btn-black1 {
  background-color: none !important;
  color: #37b7ab !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font: GT-thin !important;
}

.btn-blacks {
  background-color: none !important;
  color: #4312c7 !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-family: ST-mediums !important;
}

.btn-black1 i {
  font-size: 25px !important;
}

.btn-blacks i {
  font-size: 25px;
}

.btn-dark-green {
  background-color: #003e51 !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.btn-black {
  background-color: #01070a !important;
  color: #fff !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center !important;
}
.btn-dark-green {
  background-color: #003e51 !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.navbar-area .navbar-brand img {
  width: 150px;
}
.app.dark-mode .navbar-area .navbar {
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 12px #091e4226;
  backdrop-filter: blur(5px);
}
.navbar-area .nav-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.app.dark-mode .navbar-area .nav-link {
  font: 16px GT-medium;
  color: #fff !important;
  margin-bottom: 2px;
  cursor: pointer;
}
.navbar-area .nav-item i {
  color: #000;
}

.whiteBg {
  background: #fff;
  height: 85px;
  top: 0;
}

.app.dark-mode .hero-section {
  min-height: 80vh;
  display: flex;
  align-items: center;
  color: #fff;
}
.hero-section h1,
.eventHero h1 .dialogueII h1 {
  font: 42px ST-blacks !important;
}

.dialogueII h1 {
  font: 42px ST-blacks !important;
}
.hero-section h5,
.eventHero h5 {
  font-family: ST-lights !important;
  color: #fff;
  font-size: 19px;
  line-height: 170% !important;
}
.app.dark-mode .hero-section p {
  border: 1px solid #ffffff;
  width: 200px;
  margin: 20px auto;
  padding: 15px 0px;
}

.app.dark-mode.loved-by-section h4 {
  color: #fff;
}

.black-sector {
  background: #000000 !important;
}

.black-sector h4 {
  color: #fff !important;
}

.why-prembly-area {
  background: #fff !important;
  color: #1c343d;
  /* margin-top: 0.5rem; */
}

.app.dark-mode .infrastructure-area,
.app.dark-mode .apis-and-tools-area,
.app.dark-mode .prembly-ecosystem-area,
.app.dark-mode .app.dark-mode .solutions-area,
.app.dark-mode .get-started-area,
.latest {
  color: #fff;
}

.infrastructure-area .title {
  border: 1px solid #37b7ab;
  width: 220px;
  margin: 30px 0px;
  padding: 10px 20px;
  color: #37b7ab !important;
}
.infrastructure-area p {
  line-height: 200%;
}
.infrastructure-area .card {
  background-color: #082359;
  border-radius: 0;
}
.infrastructure-area .card p {
  font-size: x-large;
  font-family: GT-thin;
}
.infrastructure-area .card .btn-green {
  width: 220px;
}

/**************** animation area ****************/

.animation-area {
  /* position: relative; */
  background: #e9f0fc;
}

.gallery-areas {
  position: relative;
  background: #000000;
  color: #fff;
}

.gallery-areas h1 {
  font: 22px GT-Bold !important;
}

.gallery-areas p {
  font-family: GT-light;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.slick-track {
  display: flex !important;
  justify-content: center !important;
  padding: 20px;
}
.swiper-slide {
  /* background-position: center;
  background-size: cover; */
  /* width: 360px !important;
  height: 280px !important; */
  /* margin: 0px 30px !important;
  padding: 0px 20px !important; */
}

.swiper-slide img {
  /* display: block; */
  width: 300px;
  /* height: 260px; */
}

.container-fluid1 {
  padding: 10.5rem 7rem;
  margin: 2rem 7rem;
  line-height: 150%;
  background: #37b7ab;
  z-index: 1;
}

.animation-area h1 {
  color: #ffffff;
  font-size: 2.9rem;
  font-family: ST-blacks;
  text-align: center;
}

.apis-and-tools-area .title {
  margin: 30px 0px;
  padding: 10px 0px;
  color: #37b7ab !important;
}

.circle-icon {
  font-size: 105px;
  color: #ffffff;
  margin-top: 30px;
  padding-left: 20px;
  text-align: center;
}

.why-prembly-area .circle-icons {
  font-size: 100px;
  color: #005e7a !important;
  text-align: center;
}

.why-prembly-area {
  font-size: large;
}

.why-prembly-area h1 {
  color: #007da3 !important;
  font: 1.5rem GT-bold;
}

.why-prembly-area h5 {
  color: #005e7a !important;
  font: 2.8rem GT-bold;
  font-weight: bold;
  line-height: 150%;
}

.why-prembly-area .whypremblyImg {
  width: 100%;
  height: 100%;
  background: #f1fffc;
  /* transform: rotate(90deg); */
}

.why-prembly-area .upside {
  transform: rotate(270deg);
}

/**************** built ****************/
.developer-built-area {
  background-color: #fff;
  color: #fff;
  min-height: 140vh;
  display: flex;
  align-items: center;

  position: relative;
}

.developer-built-area .bgImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 140vh;
  z-index: 0;
}
.developer-built-area > .container {
  z-index: 2;
}

.developer-built-area p {
  font: 18px GT-thin !important;
  color: #ffffff;
}

.developer-built-area h1 {
  font: 50px GT-bold;
}

.developer-built-area h5 {
  font: 18px GT-light;
  line-height: 2rem;
}

/*******feedback********/

.feedbackCard .card {
  background: rgba(188, 235, 231, 0.18) !important;
  border-radius: 8px;
  height: 320px;
  padding: 30px;
  border-radius: 5px;
  border: none;
  /* box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31); */
}

.cardlightblue .card {
  background: #f8f7fb;
}

.cardColor.cardpinks .card {
  background: #fbf5fa !important;
}

.cardlightblue .card .text-start i,
.cardpinks .card .text-start i {
  color: #003e51 !important;
}

.cardlightblue .card h4,
.cardpinks .card h4 {
  color: #003e51 !important;
  font: ST-mediums 30px;
}

.cardColor .card {
  height: 320px;
  padding: 30px;
  border-radius: 5px;
  border: none;
  background: #f8f7fb !important;
}

.cardColor .card .text-start i {
  color: #4312c7;
}

.cardColor .card h4 {
  color: #4312c7;
  font: ST-mediums 30px;
}

.cardColor .card p {
  font: 16px ST-lights;
  line-height: 1.5rem;
}

.quote-left {
  float: right;
}

.cardColor .card:hover {
  color: #fff !important;
  background: #003e51 !important;
}

.cardColor .card:hover p,
.cardColor .card:hover h4 {
  color: #ffff !important;
}

.feedbackCard .card:hover {
  color: #fff !important;
  background: #003e51 !important;
}

.feedbackCard .card:hover p,
.feedbackCard .card:hover h4,
.cardColor .card:hover i {
  color: #ffff !important;
}

.feedbackCard .quote-left {
  float: right;
}

.diffCard {
  z-index: 3;
  padding-bottom: 4.5rem;
}

.cardhead i {
  color: #ffffff;
  font-size: 20px;
}

.showflex {
  /* display: flex !important; */
}

.avatarimg {
  display: flex;
  gap: 1rem;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 2rem;
}

.feedbackCard .card h4 {
  font-family: ST-bolds;
  font-size: 23px;
  color: #0c0c0c;
}

.email {
  font-size: 16px;
  font-family: GT-light !important;
  color: #000000 !important;
}

.feedback-prembly-area {
  display: flex;
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/Content.svg")
    center center no-repeat !important;
  background-size: cover;
  align-items: center;
}

.feedback-icons-styles {
  display: flex;
  justify-content: center;
}

.feedback-prembly-area h1 {
  font: 46px ST-blacks !important;
}

.feedbackCard .feedback-title p {
  color: #232323;
  font-size: 16px !important;
  font-family: ST-lights !important;
}

.feedbackCard p:hover {
  color: #ffff;
}

/* second feedback */
.second-feedback {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 2rem;
}

.feedbacks {
  display: flex !important;
  justify-content: center !important;
}

.feedbacksforusers {
  display: grid !important;
  grid-template-columns: repeat(3, 2fr) !important;
  gap: 1rem;
  justify-content: center !important;
}

/*******feedback end********/

/******* prembly-ecosystem ********/
.prembly-ecosystem-area {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/longbg.svg")
    center center no-repeat !important;
  align-items: center !important;
  background-size: cover !important;
}

.prembly-ecosystem-area p {
  font-size: large;
}

.btn-purple {
  background-color: #e3deff !important;
  color: #3013e1 !important;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.413) !important;
  margin-left: 10px;
}

.btn-lights {
  background-color: #b0d3fd !important;
  color: #0768dd !important;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.413) !important;
  margin-left: 10px;
}

.radar-card .btn-radar {
  background: #fff1fa !important;
  color: #de00b6;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid #e3deff;
  margin-left: 10px;
}

.btn-radars {
  background: #fff1fa !important;
  color: #de00b6 !important;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid #fff;
  margin-left: 10px;
}

.btn-graph {
  background: #bcebe7 !important;
  color: #37b7ab !important;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid #e3deff;
  margin-left: 10px;
}

.black-graph {
  background: #bcebe7 !important;
  color: #003e51 !important;
  border-radius: 14px !important;
  /* width: 110px; */
  height: 40px;
  font-size: 15px !important;
  display: flex;
  font-family: ST-blacks !important;
  text-align: center;
  justify-content: center;
  border: 1px solid #e3deff;
  margin-left: 10px;
}

.radar-card .btn-graph {
  background-color: transparent !important;
  /* color: #DE00B6 !important; */
  border-radius: 0 !important;
  /* border: 1px solid#E3DEFF !important; */
}

.btn-purple-outline,
.btn-radar {
  background-color: transparent !important;
  color: #de00b6 !important;
  border-radius: 0 !important;
  border: 1px solid #e3deff !important;
}

.first-text {
  color: #04141a !important;
  font: 16px ST-lights !important;
  line-height: 1.5rem !important;
}

.eco-headings h1 {
  font: 38px ST-blacks !important;
}

.newprembly-row {
  /* max-width: 400px !important; */
}

.newprembly-list {
}

.newprembly-row .newprembly-list .t {
  color: #000000;
  font: 16px GT-thin !important;
}

.prembly-row {
  /* display: flex;
  justify-content: center !important;
  align-items: center;
  gap: 5rem; */
}

.prembly-card {
  width: 100%;
  background: #e3deff;
  height: 450px;
}

.prembly-card-radar {
  width: 100%;
  background: #fff1fa;
  height: 450px;
}

.prembly-card-graph {
  width: 100%;
  background: #eaffff;
  /* #BCEBE7 */
  height: 450px;
}

.get-started-area {
  /* background: #f5fdff; */
  padding: 3rem 0;
}

.get-it-row {
  gap: 3rem;
}

.get-started-areapink {
  background: #fbf5fa;
}

.get-started-areablue {
  background: #f8f7fb;
}

.get-started-area p {
  font-size: x-large;
  line-height: 150%;
}

.structure h1 {
  color: #005e7a !important;
  font: 29px GT-bold !important;
}

.structure p {
  color: #005e7a !important;
  font: 18px GT-light !important;
}

/******* faqs**********/
.faq .question {
  font-family: GT-bold !important;
  color: #000 !important;
}
.question-bg {
  background-color: #f0f3fb;
  margin-bottom: 20px;
  cursor: pointer;
}
/* .faq .answer{
  background-color: #fff;
  padding:20px;
} */
/* .faq .accordion-item{
  border: none !important;
}
.faq .accordion-body{
  padding: 0;
}
.faq .accordion-button{
  background-color: transparent !important;
}
.faq .accordion-collapse .show{
  font-family:GT-bold;
  color:#000 !important
} */

.accordion {
  /* --bs-accordion-btn-focus-border-color: none !important; */
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: none;
  --bs-accordion-active-bg: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion .item {
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 1rem 1rem;
  margin-bottom: 1.5rem;
  background-color: transparent;
  text-align: left;
  font-family: ST-lights;
}

.accordion .item .accordion .header button {
  background-color: transparent;
  box-shadow: none;
  font-size: 1rem;
  font-family: ST-blacks;
  letter-spacing: 0.125rem;
  line-height: 1.375rem;
  color: #000000;
  vertical-align: middle;
  position: relative;
  padding-bottom: 0;
  padding-top: 0;
}

.accordion .body {
  font: 15px St-lights !important;
}

.accordion-button {
  background-color: none !important;
  background: none !important;
  font-size: 15px;
  color: #01070a;
  font-family: ST-regulars !important;
}

.accordion-button:not(.collapsed) {
  color: #000000ba !important;
  /* font-size: 15px; */
  font-family: ST-blacks;
}

.accordion:last-child .item {
  /* border-top: 2px dashed #d3d3d3; */
}

.accordion .item .accordion .header button::after {
  transition: all 0.6s ease;
}

.accordion .item .accordion-collapse {
  transition: all 0.4s ease;
}
/******* ends faqs**********/

/**************** identitypass page css start ****************/
.identitypassHero {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/bluehero.png")
    center center no-repeat !important;
  background-size: cover !important;
  align-items: center !important;
  color: #1d2939 !important;
}

.eventHero {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.53) 63.54%,
      #000000 100%
    ),
    url("../src/assets/events/head.png") center no-repeat;
  background-size: cover !important;
  align-items: center !important;
  /* color: #ffffff !important; */
  padding-top: 12rem;
  padding-left: 1.5rem;
}

.eventLocation {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin-top: -15px;
}

.loc {
  display: flex;
  /* align-items: center; */
  gap: 10px;
}

.loc p {
  font: 19px ST-lights;
}
.loc i {
  font: 20px ST-lights;
  margin-top: 3px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.timer-container {
  /* margin-top: 7rem; */
}

.clock {
  display: flex;
  gap: 1rem;
}

.clock p {
  font: 60px ST-mediums;
}

.clock small {
  font: 15px ST-lights;
}

.clock-timer {
  margin-top: 30px;
}

.sector-area {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/heroind.png")
    center center no-repeat !important;
  background-size: cover !important;
  align-items: center !important;
  color: #1d2939 !important;
}

.sector-area h1 {
  font: 46px ST-blacks !important;
}

.radar-hero-area {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/pinkhero.png")
    center center no-repeat !important;
  background-size: cover !important;
  align-items: center !important;
  color: #1d2939 !important;
}

.identitypassHero h1,
.eventHero h1,
.dialogueII h1 {
  font: 46px ST-blacks !important;
}

.graphHeader {
  gap: 3rem;
}

.idherocolor {
  gap: 3rem;
}

.radar-hero-area .graphHeader h1,
.idherocolor,
h1 {
  font: 47px ST-blacks !important;
}

.radar-hero-area .graphHeader p,
.idherocolor p {
  font: 20px ST-lights !important;
  line-height: 2rem !important;
  color: #1d2939 !important;
}

.identitypassHero h5,
.sector-area h5,
.eventHero h5 {
  font: 20px ST-lights !important;
  line-height: 2rem !important;
  color: #1d2939 !important;
}

.eventHero h5 {
  font: 20px ST-lights !important;
  line-height: 2rem !important;
  color: #ffff !important;
}

.radar-hero-area h5 {
  font: 20px ST-lights !important;
  line-height: 2rem !important;
  color: #1d2939 !important;
}

.how-to-start-area {
  background: #e9f0fc;
  padding: 6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-to-start-areas {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/longbg.svg")
    center center no-repeat !important;
  background-size: cover;
  display: flex;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
}

.howToGetstartContent {
  display: flex;
  gap: 2rem;
}

.how-to-start-areas .card {
  box-shadow: 0px 3.827150583267212px 19.135753631591797px 0px #091e424f;
  padding: 15px;
  animation: 0.5s animation-top ease-in-out;
}
.how-to-start-areas .card-body {
  display: flex;
}
.how-to-start-areas .card-body h5 {
  color: #1633a3;
}
.how-to-start-areas .inactive {
  cursor: pointer;
}
.how-to-start-areas .inactive i {
  font-size: 20px !important;
}
.how-to-start-areas.inactive h5 {
  padding: 0 !important;
  margin: 0 !important;
}

.ticks i {
  background-color: #fff;
  margin-left: 10px !important;
}

.longimg {
  margin-top: 6rem !important;
}

.code-sec {
  gap: 3rem;
}

.ticks {
  /* border: 1px solid #37b7ab; */
  border-radius: 50%;
  width: 25px;
  height: 25px;

  border: 1px solid #1633a3;
}

.ticks {
  background-color: #fafbfc;
}

.id-features-area {
  background: #fff !important;
}

.id-features-area h1 {
  color: #000000;
  font: 45px GT-medium !important;
  line-height: 150%;
}

.id-feature-area h1 {
  font: 45px GT-bold !important;
  color: #07485e;
}

.id-features-area p {
  color: #000000;
  font: 18px GT-light !important;
}
.verification-types {
  background: #007da3 !important;
  padding: 3rem 2rem 5rem 2rem !important;
  border-radius: 20px;
  color: #ffffff !important;
  width: 30%;
  height: 100%;
}

.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 630px;
}

.box-1 h1 {
  color: #003e51 !important;
}

.verifyContent .box-title {
  color: #003e51 !important;
}

.box-1 {
  background: #c3dfee !important;
  color: #003e51 !important;
}

.box-2 {
  background-color: #07485e !important;
  color: #07485e !important;
}

.verification-types .verifyContent h1 {
  color: #fff;
  font: 35px GT-medium !important;
}

.small-card {
  width: 100% !important;
  height: 190px;
  background: #ffffff;
}

.green-card {
  width: 100% !important;
  height: 190px;
  background: #003e51;
}

.verification-types .verifyContent p {
  line-height: 1.6rem !important;
  color: #fff;
  font-family: GT-thin !important;
  font-size: 17px !important;
}

.center-title h1 {
  font: 38px GT-bold !important;
}

.center-title p {
  font-family: GT-light !important;
  color: #000000;
  /* max-width: 8%; */
}

.different-content {
  width: 40% !important;
  /* text-align: justify; */
}

.different-content > h2 {
  color: #37b7ab;
  font: 17px GT-medium !important;
  /* background: #fff; */
}

.different-content p {
  color: #000000;
  font-family: GT-thin !important;
  font-size: 18px;
}

.row-different {
  padding-top: 2rem !important;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

/*************** end *****************/

/******* countries ********/
.countries-section {
  background: #000000;
  align-items: center;
}

.countries-section .countries-list h1 {
  color: #ffffff;
  font-size: 38px !important;
  font-family: ST-blacks !important;
}

.countries-section .countries-list h5 {
  font-size: 17px !important;
  font-family: ST-lights !important;
  color: #ffffff !important;
  line-height: 1.7rem !important;
}

.global-color {
  width: 81px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 12px;
  /* padding: 5px; */
  background: #bcebe7;
  color: #37b7ab;
}

.global-blue {
  width: 81px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 12px;
  /* padding: 5px; */
  background: #bfb6fc;
  color: #4312c7;
}

.global-lightblue {
  width: 81px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 12px;
  /* padding: 5px; */
  background: #b0d3fd !important;
  color: #0768dd;
}

.global-lightblue {
  background: #b0d3fd !important;
  color: #0768dd !important;
}

.global-blue {
  background: #bfb6fc;
  color: #4312c7 !important;
}

.globalpink {
  width: 81px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  border-radius: 12px;
  /* padding: 5px; */
  background: #bcebe7;
  color: #37b7ab;
}

.globalpink {
  background: #fbf5fa;
  color: #ef5da8 !important;
}

.globalpink p {
  font-size: 16px !important;
  font-family: ST-blacks !important;
  margin-bottom: 0rem !important;
}

.global-color p {
  font-size: 16px !important;
  font-family: ST-blacks !important;
  margin-bottom: 0rem !important;
}
/************** solutions*****************/

.financial-solution {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/financialbgs.svg")
    center center no-repeat !important;
  align-items: center;
  /* background:url('assets/bluehero.png') center center no-repeat !important; */
  background-size: cover !important;
  align-items: center !important;

  color: #1d2939 !important;
}

.loved-financial {
  background: #000000;
}

.secondDivContent {
  height: 450px;
  width: 100%;
}

.secondFinancialContent {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
  position: relative;
  /* background: #ffffff; */
  border-radius: 15px;
}

.secondFinancialContent1 {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
  position: relative;
  /* background: #ffffff; */
  border-radius: 15px;
}

.secondFinancialContent::before {
  content: "";
  width: 100%;
  height: 450px;
  position: absolute;
  z-index: -1;
  border: solid 1px #ffffff;
  top: -20px;
  right: -20px;
  border-radius: 15px;
}

.marquee {
  padding: 30px 0px;
  margin: 30px 0px;
  width: var(--marquee-width);
  /* height: var(--marquee-height); */
  /* background-color: #efff; */
  overflow: hidden;
  position: relative;
}

.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 1rem;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
  /* background: linear-gradient(to right, #eee 0%, transparent 100%); */
}

.marquee:after {
  right: 0;
  /* background: linear-gradient(to right, #eee 0%, transparent 100%); */
}

.marquee-content {
  list-style: none;
  /* height: ; */
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
    );
  }
}

.marquee-content ul {
  display: flex;
  align-items: center;
}

.marquee-content li {
  list-style: none;
}

.marquee-content li img {
  width: 1400px;
}

.marquee-content:hover {
  animation-play-state: paused;
}

.logosimg img {
  width: 100px;
}

.logosimgs img {
  width: 200px;
}

.secondDivContents {
  width: 100%;
  margin-left: -5rem !important;
  position: relative;
}

.custom h1 {
  color: #4312c7 !important;
  font: 46px ST-blacks;
  margin-bottom: 40px;
}

.quickhelp p {
  line-height: 7.5rem !important;
}

.quickhelp h1 {
  font: 45px ST-blacks !important;
  color: #4312c7 !important;
}

.quickhelp p {
  font: 18px ST-lights !important;
  line-height: 2rem !important;
  color: #000000;
}

.authentic {
  text-align: center;
}

.authentic h1 {
  font: 45px ST-blacks !important;
  color: #4312c7 !important;
}

.authentic p {
  font: 18px ST-lights !important;
  line-height: 2rem !important;
  color: #000000;
}

.seperate-contents {
  background: linear-gradient(
    180deg,
    #fbf4f9 0%,
    rgba(245, 249, 255, 0) 94.12%
  ) !important;
}

.seperate-contentsblue {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/checks.png") !important;
  align-items: center;
}

.verifyallapplicant {
  position: relative;
  gap: 6rem;
  padding: 5rem 7rem !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.verify-applicants {
  position: relative;
  border-radius: 15px;
  width: 470px;
  z-index: 1;
}

#magic-bg img {
  border-radius: 17px !important;
}

.newLeft {
  display: none;
}

.verify-applicants::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border: solid 1px #000000;
  right: -20px;
  top: -20px;
  z-index: -1;
  border-radius: 15px;
}

.verifyapplicantstoday h1 {
  font: 48px ST-blacks !important;
  color: #4312c7;
}

.verifyapplicantstodaygreen h1 {
  font: 40px ST-blacks !important;
  color: #003e51;
}

.verifyapplicantstodaygreen p {
  font: 18px ST-lights !important;
  color: #003e51;
  line-height: 2rem !important;
}

.verifyapplicantstodays h1 {
  font: 48px ST-blacks !important;
  color: #de00b6 !important;
}

.quickhelps {
  text-align: center;
}

.quickhelps p {
  font: 18px ST-lights !important;
  line-height: 2rem !important;
}

.radar-business h1 {
  font: 45px ST-blacks !important;
  color: #de00b6 !important;
}

.verifyapplicantstoday p,
.verifyapplicantstodays p {
  font: 18px ST-lights !important;
  line-height: 2rem !important;
}

.verifyapplicantstoday .exploreBg {
  font: 21px GT-medium !important;
}

.verifyapplicantstoday .exploreBg i {
  font-size: 21px;
  padding-top: 31px !important;
}

.explorechecks {
  display: flex;
  align-items: center;
}

.secondFinancialContents {
  width: 100%;
  height: 100%;
  margin-left: 5rem;
  border-radius: 15px;
  z-index: 3;
}

.secondFinancialContents::before {
  content: "";
  width: 450px;
  height: 470px;
  position: absolute;
  border: solid 1px #000000;
  top: -20px;
  right: -20px;
  border-radius: 15px;
  z-index: -1;
}

.bgCheck {
  height: 460px;
}

.bgInner {
  width: 100%;
  height: 100%;

  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
  border-radius: 15px;
}

.bgContent {
  text-align: justify;
}

.bgContent h1 {
  font: 33px GT-medium !important ;
  margin-bottom: 20px;
  color: #003e51;
}

.bgContent h5 {
  font: 20px GT-light !important ;
  line-height: 1.8rem !important;
}

.bgInner::before {
  content: "";
  width: 100%;
  height: 450px;
  position: absolute;
  z-index: -5;
  border: solid 1px #000000;
  top: -20px;
  right: -20px;
  border-radius: 15px;
}

.allVerificationNeeded,
.helpingFt,
.about-us {
  background: linear-gradient(
    180deg,
    #f5f9ff 0%,
    rgba(245, 249, 255, 0) 94.12%
  );
  justify-content: center;
  align-items: center;
  padding-bottom: 8rem;
  padding-top: 5rem;
}

.helpingFtCardRadar {
  background: radial-gradient(
    142.42% 481.27% at 94.78% -0.01%,
    rgba(185, 196, 255, 0.57) 0%,
    rgba(164, 228, 255, 0.22) 100%
  );
  border-radius: 24px !important;
  padding: 30px 25px 3rem 25px;
  border: none !important;
}

.allVerificationNeeded {
  background: linear-gradient(
    180deg,
    #f5f9ff 0%,
    rgba(245, 249, 255, 0) 94.12%
  ) !important;
}
.allVerificationNeeded h1,
.how-prembly-helps h1 {
  font: 42px GT-bold !important;
  line-height: 5rem;
  color: #183b56;
}

.helpingFt h1,
.question h1 {
  font: 47px GT-bold !important;
}

.question h1 {
  font: 33px GT-medium !important;
}
.allVerificationNeeded p,
.how-prembly-helps p {
  font: 18px GT-light !important;
  line-height: 1.7rem !important;
  color: #183b56;
}

.financialCard {
  width: 470px;
  height: 600px;
  background: linear-gradient(
    180deg,
    #f5f9ff 0%,
    rgba(245, 249, 255, 0) 94.12%
  );
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  margin-left: 8rem;
}

.financialheader {
  padding: 30px 10px 25px 25px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90.03deg, #07485e 0.03%, #37b7ab 99.98%);
  box-shadow: 0px 0px 9px 2px rgba(7, 72, 94, 0.25);
  border-radius: 4px;
  width: 100%;
}

.financialheader h1 {
  color: #fff;
  font: 25px GT-medium !important;
}

.financialheader p {
  line-height: 1.5rem !important;

  max-width: 450px;
  color: #fff;
  font: 16px GT-thin;
}

.financial-list {
  padding-top: 10px;
}

.financial-items {
  color: #003e51;
  border-bottom: 1px solid #a0aec4;
  padding: 45px 0;
}

.financial-list h2 {
  font: 18px GT-medium !important;
}

.helpingFtCard {
  background: radial-gradient(
    112.39% 79.92% at 65.87% 20.08%,
    #d3daff 0%,
    rgba(219, 240, 255, 0.28) 100%
  ) !important;
  border-radius: 24px !important;
  padding: 30px 25px 3rem 25px;
  border: none !important;
  /* width: 300px; */
}

.helpingFtCardForm {
  background: radial-gradient(
    188.3% 469.06% at 100% -5.11%,
    #ffe3f6 0%,
    rgba(186, 176, 252, 0.04) 100%
  ) !important;
  border-radius: 24px !important;
  padding: 30px 25px 3rem 25px;
  border: none !important;
}

/************* questions ****************/
.question-list {
  display: block;
  align-items: center;
  gap: 2rem;
  padding-top: 30px;
}

.question-lists {
  display: block;
  /* gap: 2rem; */
}

.question-content {
  max-width: 100%;
  height: 75px;
  padding: 20px;
  border: 1px solid rgba(204, 204, 204, 0.732);
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  color: #000000;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.question-contents {
  width: 100%;
  height: 150px;
  padding: 20px;
  border: 1px solid rgba(204, 204, 204, 0.732);
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  color: #000000;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.big-textarea h3 {
  font: 19px GT-bold !important;
}

.big-textarea p {
}

.question-contents {
}

.question-content p {
  font: 14px GT-thin !important;
  margin-top: 10px;
}

.question-content i {
  font-size: 25px;
}

.question-contents p {
  font: 15px GT-thin !important;
  margin-top: 10px;
  color: #888580;
  line-height: 2.2rem !important;
}

.question-contents i {
  font-size: 25px;
  color: rgba(119, 119, 119, 0.472);
}
/****************sme start****************/

.accuratedata-area h1 {
  font-family: GT-bold;
  padding-bottom: 20px;
}

.accurate-lists {
  background: radial-gradient(
    112.39% 79.92% at 65.87% 20.08%,
    #d3daff 0%,
    rgba(219, 240, 255, 0.28) 100%
  ) !important;
  border-radius: 24px;
  padding: 0rem 2rem 2rem 4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.accurate-lists1 {
  background: radial-gradient(
    188.3% 469.06% at 100% -5.11%,
    #ffe3f6 0%,
    rgba(186, 176, 252, 0.04) 100%
  );
  border-radius: 24px;
  padding: 0rem 2rem 2rem 4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.accurate-lists2 {
  background: radial-gradient(
    142.42% 481.27% at 94.78% -0.01%,
    rgba(185, 196, 255, 0.57) 0%,
    rgba(164, 228, 255, 0.22) 100%
  );
  border-radius: 24px;
  padding: 0rem 2rem 2rem 4rem;
  display: flex;
  justify-content: space-between;
}

.accuratecontent2 {
  background: radial-gradient(
    91.89% 133.77% at 25.56% 30.69%,
    rgba(199, 191, 246, 0) 0%,
    #f5fdff 100%
  );
  border-radius: 8px;
  padding: 30px 25px 1rem 25px !important;
  width: 46%;
  padding: 25px;
}

.accuratecontent3 {
  background: radial-gradient(
    91.89% 133.77% at 25.56% 30.69%,
    rgba(254, 199, 235, 0) 0%,
    #f5fdff 100%
  );
  border-radius: 8px;
  padding: 30px 25px 1rem 25px !important;
  width: 46%;
  padding: 25px;
}

.accuratecontent4 {
  background: radial-gradient(
    91.89% 133.77% at 25.56% 30.69%,
    rgba(254, 199, 235, 0) 0%,
    #f5fdff 100%
  );
  border-radius: 8px;
  padding: 30px 25px 1rem 25px !important;
  width: 46%;
  padding: 25px;
}

.helpingFtCard1 {
  /* background: radial-gradient(112.39% 79.92% at 65.87% 20.08%, #D3DAFF 0%, rgba(219, 240, 255, 0.28) 100%) !important; */
  border-radius: 24px !important;
  padding: 30px 25px 3rem 25px;
  width: 73%;
  border: none !important;
  /* width: 300px; */
}
.accuratecontent2 h1,
.accuratecontent3 h1,
.accuratecontent4 h1 {
  font: 23px GT-medium !important;
  padding-bottom: 1.3rem;
}

.idverification-row {
  gap: 0.1rem;
}
.accuratecontent2 p {
  font: 16px GT-light;
  line-height: 1.5rem;
  padding-bottom: 10px;
}

.idverification {
  margin-left: -10px !important;
}
.middle-line {
  height: 400px;
  width: 2px;
  background: #fff;
}
/****************sme end****************/

/************** solution ends*****************/

/************ Resource start************/
.press {
  background: #fff;
}

.press h1 {
  font: 60px GT-bold !important;
}

.press p {
  font: 18px GT-light !important;
}
.containerPress {
  min-height: 80vh;
  background: #fff;
  padding: 10rem 7rem 0 7rem;
  position: relative;
  z-index: 2;
}
/* .containerPress-bg {
  position: absolute;
  height: 600px;
  width: 100%;
  background: #e9f0fc;
  top: 0;
  left: 0;
  z-index: -1;
} */
.navigateBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem !important;
}

.btnSwitch {
  width: 400px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #101828 !important;
  background: #fff;
  border: 1px solid #003241;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  font-family: GT-regular !important;
}

.connects {
  display: block;
  justify-content: center !important;
  align-items: center;
  padding: 0 10rem;
}

.active {
  background-color: #003e51;
  color: white !important;
}

.newsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.lastestNews {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.containerPress {
  padding: 10rem 3rem 0 3rem;
}

.pressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logosContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: center;

  justify-content: center;
}

.logoCard {
  /* padding: 10px; */
  width: 274px;
  height: 333px;
  border: 1px solid #a1aec5;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
}

.icon-download {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  color: #fff !important;
  padding-top: 2rem;
  /* gap: 3rem; */
}

.logo-body {
  padding: 20px;
}

.icon-download p {
  font: 15px GT-medium !important;
}

.icon-download i {
  color: #fff;
  font-size: 20px;
}

.icon-download a {
  margin-top: -15px;
}

.newsCard .card {
  cursor: pointer;
  width: 303px;
  height: 400px !important;
  border: none !important;
  background: none;
}

.event-titles {
  padding-top: 15rem;
  max-width: 50%;
}

.newsCard .card img {
  border-radius: 6px 6px 0 0px;
}

.newsCard .card:hover {
  background: #003241 !important;
  border-radius: 6px;
  /* color: #ffffff; */
}

.newsCard .card:hover p {
  color: #ffffff !important;
  font-family: ST-regulars !important;
}

.newsCard .card:hover h5 {
  color: #ffffff !important;
  font-family: ST-regulars !important;
}

.newsCard .card:hover p {
  font-family: ST-regulars !important;
}

.new-body:hover {
  color: #ffffff !important;
}

.news-header {
  width: 100%;
  height: 250px;
}

.news-header img {
  width: 100%;
}

.press-header {
  width: 100%;
}

.new-body {
  margin-top: 2rem;
  padding-left: 7px;
}

.press-body {
  margin-top: 2.7rem;
}

.press-body h5 {
  font: 25px ST-mediums !important;
  line-height: 2rem !important;
}

.new-body h5 {
  font: 16px ST-mediums !important;
  line-height: 1.5rem !important;
  color: #101828;
}

.press-body h4 {
  font: 19px ST-mediums;
  color: #475467;
}

.new-body p {
  font-size: 12px !important;
  color: #504c4c;
  /* border-top: 1px s; */
}

.frequentQs-area {
  height: 100%;
  padding: 12rem 0 4rem 0;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(90.03deg, #07485e 0.03%, #37b7ab 99.98%);
}

.firstlayer h1 {
  font: 50px GT-bold !important;
  color: #fff;
}

.firstlayers h1 {
  font: 50px GT-bold !important;
  color: #fff;
}

.plans {
  gap: 3rem;
}

.searchelp {
  height: 58px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.searchelp i {
  font-size: 20px;
  color: #777;
}

.searchelp input {
  flex: 1;
  height: 55px;
  border: none;
  outline: none;
  font-size: 18px;
  padding-left: 10px;
}

.searchelp ::placeholder {
  font-size: 14px;
}

.searchelp input[type="search"] {
  padding: 20px;
}

.section-plan-box {
  height: 500px;
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 3rem 1.5rem !important;
}

.section-plan-box h5 {
  font: 22px GT-bold !important;
  color: #000000;
}

.sectionplan-list {
  /* padding: 0rem 1rem; */
}

.sectionplan-list p {
  font: 18px GT-regular !important;
  padding: 20px 0;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}

.sectionplan-list h4 {
  font: 18px GT-bold !important;
  color: #000000;
  padding: 20px 0;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}

/************Resource end************/

.footer-area {
  background-size: cover;
  position: relative;
  color: #fff;
}
.footer-area .container {
  background: url("https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/footer-world_hbeaxf.png")
    center center no-repeat;
  background-size: cover;
}

.footer-area .container .row {
  /* padding-bottom: 50px; */
}
.footer-area i {
  font-size: 30px;
  margin-right: 10px;
}
.footer-area a,
.footer-area .link {
  color: #fff !important;
}
.footer-area a p {
  font-size: large;
  font-family: GT-thin !important;
}

.footer-contents {
  /* gap: 2rem; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.policies-title {
  font-size: 16px !important;
}

.ant-modal .ant-modal-close-x {
  color: #ffffff;
}

.ant-modal .ant-modal-content {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51 !important;
  border-radius: 8px;
  margin-top: 70px;
  z-index: 2000;
  padding: 5rem 2rem 3rem 2rem;
  max-width: 590px;
  width: 590px;
  /* width: 100% !important; */
  display: block;
}

.ant-modal-wrap {
  z-index: 2000 !important;
}

/* .cookie-box{
  background: #003E51;
} */

.cookie-box p {
  color: #ffffff;
  margin-top: 25px;
  font-size: 17px;
  font-family: ST-regulars;
  line-height: 32px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  text-align: justify;
}

.cookie-box .cookie-link {
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #ffffff;
}

.cookie-btn {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  justify-content: space-between;
}

.btn-green2 {
  background-color: #37b7ab !important;
  color: #fff !important;
  border-radius: 6px !important;
  display: flex;
  padding: 13px 40px !important;
  text-align: center;
  font-family: ST-bolds !important;
  justify-content: center;
  border: 1px solid #37b7ab;
}

.btn-greennew {
  width: 100% !important;
  background-color: #bcebe7 !important;
  color: #37b7ab !important;
  border-radius: 6px !important;
  display: flex;
  padding: 13px 40px !important;
  text-align: center;
  font-family: ST-bolds !important;
  justify-content: center;
  border: 1px solid #bcebe7;
  margin-top: 1rem;
}

/* dark mode ends here */

/* light mode starts here */
.app.light-mode {
  background-color: #f3f8f7 !important;
}

.app.light-mode .tick {
  border: 1px solid #37b7ab;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 15px !important;
}
.app.light-mode .tick i {
  position: relative;
  background-color: #f3f8f7;
  border-radius: 50%;
  top: -3px;
  right: -8px !important;
  color: #37b7ab;
}

.app.light-mode .tick1 {
  border: 1px solid #37b7ab;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 15px !important;
}
.app.light-mode .tick1 i {
  position: relative;
  background-color: rgba(255, 255, 255, 0.605);
  border-radius: 50%;
  top: -2px;
  right: -11px !important;
  color: #37b7ab;
}

.navbar-area {
  /* background-color: #ffff;
  height: 90px; */
}

.app.light-mode .navbar-area .navbar {
  background: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(12px);
  border-radius: 10px;
  box-shadow: 0px 0px 12px #091e4226;
  margin: 30px;
}
.navbar-area .nav-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.navbar-area .nav-link {
  font: 16px ST-regulars;
  color: #475467 !important;
  margin-bottom: 2px;
  cursor: pointer;
}

.app.light-mode .navbar-area {
  font: 16px GT-medium;
  color: #07485e !important;
  margin-bottom: 2px;
  cursor: pointer;
}
.navbar-area .nav-item i {
  /* color:#000; */
  color: #475467 !important;
}

.app.light-mode .hero-section {
  min-height: 80vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
  color: #fff;
  position: relative;
  z-index: 1;
}

.app.light-mode .container-fluid,
.random-background-area .container {
  padding: 8rem 7rem;
  line-height: 150%;

  /* background: rgba(255, 255, 255, 0.3) !important; */
  /* backdrop-filter: blur(5px); */
  z-index: 1;
}

.hero-section h5,
.eventHero h5,
.dialogueII h5 {
  font-family: GT-regular;
  line-height: 150%;
}

.app.light-mode .hero-section p {
  border: 1px solid #ffffff;
  width: 200px;
  text-align: center;
  color: #fff;
  padding: 10px 0px;
}

.app.light-mode .eventHero {
  min-height: 80vh;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.53) 63.54%,
      #000000 100%
    ),
    url("../src/assets/events/head.png");
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.app.light-mode .dialogueII {
  min-height: 80vh;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.53) 63.54%,
      #000000 100%
    ),
    url("../src//assets/prembly-assest/photo.png");
  /* https://prembly-assets.s3.us-east-2.amazonaws.com/photo.png" */
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  animation: change 15s infinite ease-in-out;
}

@keyframes change {
  0% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/photo.png");
  }
  20% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/large1.png");
  }
  40% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/large2.png");
  }
  60% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/large3.png");
  }
  80% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/large4.png");
  }
  100% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/large5.png");
  }
  120% {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.53) 63.54%,
        #000000 100%
      ),
      url("../src//assets//prembly-assest/laarge6.png");
  }
}
.dialogue-btn {
  display: flex;
  justify-content: center !important;
}

.text-dialogue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app.light-mode .loved-by-section h4 {
  color: #07485e;
  font: 30px ST-blacks;
}
.app.light-mode .loved-by-section img {
  height: 60%;
}

.get-started-area h1 {
  font: 48px ST-blacks;
  color: #003e51;
}

.get-started-area p {
  font: 18px ST-lights;
  color: #04141a;
  line-height: 1.7rem !important;
}

.get-started-area .brown {
  width: 100%;
  height: 430px;
}

.get-started-area .btn-lightgreen {
  background-color: #007da3 !important;
  color: #fff !important;
  border-radius: 0 !important;
  height: 45px;
  border-radius: 5px !important;
}

.get-started-area .structure .btn-green {
  color: #fff !important;
  font: 19px Gt-light;
  letter-spacing: 2px;
}

.why-prembly-area h2 {
  font: 1.7rem GT-bold !important;
  line-height: 4rem !important;
}

.why-prembly-area h3 {
  font: 1.5rem GT-bold !important;
}

.why-prembly-area p {
  font-family: GT-light !important;
}

.why-prembly-area .sideline {
  height: 15%;
  width: 1px;
  background: #f6b7c3;
  position: absolute;
}

.prembly-ecosystem-area h1 {
  color: #003e51;
  font-family: ST-blacks !important;
  font-weight: bold;
  font-size: 46px !important;
  /* line-height: 3.8rem !important; */
}

.app.light-mode,
.app.light-mode .prembly-ecosystem-area h3,
.why-prembly-area h3,
.app.light-mode .app.light-mode .prembly-product-area h3,
.app.light-mode .app.light-mode .solutions-area,
.app.light-mode {
  color: #003e51;
}
.infrastructure-area p {
  line-height: 200%;
}
.infrastructure-area .card {
  background-color: #082359;
  border-radius: 0;
}
.infrastructure-area .card p {
  font-size: x-large;
  font-family: GT-thin;
  color: #ffffff;
}
.infrastructure-area .card .btn-green {
  width: 220px;
}

.apis-and-tools-area .title {
  margin: 30px 0px;
  padding: 10px 0px;
  color: #37b7ab !important;
}

.prembly-ecosystem-area p {
  font-size: large;
  color: #005e7a;
  font-family: ST-lights;
  font-size: 18px !important;
}

.app.light-mode .pageTab {
  display: flex;
  border-bottom: 2px solid #e3f0ef;
  list-style-type: none;
  justify-content: space-between;
  margin: 0 !important;
  padding: 0 !important;
}
.app.light-mode .pageTab li h5 {
  color: #07485e;
  padding: 10px;
  margin: 0 !important;
  cursor: pointer;
}
.app.light-mode .pageTab li h5.active {
  border-bottom: 2px solid #37b7ab;
}
.app.light-mode .pageTab p.py-3 {
  font-size: 20px !important;
}

.solutions-area .card {
  min-height: 450px;
  border-radius: 0;
  color: #fff;
  border: 0;
}
.solutions-area .card.banking {
  background: #4e9fe0;
}
.solutions-area .card.e-commerce {
  background-color: #e3a6b9;
}
.solutions-area .card.lending {
  background-color: #37b7ab;
}
.solutions-area .card.logistics {
  background-color: #de6b71;
}
.solutions-area .use-cases .card {
  min-height: 300px;
}
.solutions-area .use-cases .card.user {
  background-color: #07485e;
}
.solutions-area .use-cases .card.business {
  background-color: #e59c8c;
}

.app.light-mode .certificate-area {
  background-color: #005e7a;
  color: #fff;
}

.certificate-area h2 {
  font: 35px GT-bold !important;
  line-height: 130% !important;
}

.certificate-area p {
  font: 19px GT-light;
  line-height: 130% !important;
}

.app.light-mode .radar-hero-area {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
  color: #fff;
  min-height: 85vh;
  display: flex;
  align-items: center;
}

.swift {
  background: #ffffff;
  color: #000000;
}

.pinkswift {
  background: linear-gradient(
    180deg,
    #fbf4f9 0%,
    rgba(245, 249, 255, 0) 94.12%
  ) !important;
}

.swift h2 {
  font: 48px GT-bold !important;
}

.swift p {
  font: 19px GT-thin;
  line-height: 1.7rem !important;
}

.swift .emailimg {
  /* width: 20rem;
  height: 20rem; */
}

.suited-tool-area h1,
.how-radar-helps-area h1 {
  font: 50px GT-bold !important;
  color: #000000;
}

.suited-tool-area p {
  font: 18px GT-light !important;
}

.radar-container {
  padding-top: 14rem;
}

.radar-features {
  padding: 50px 0;
  display: flex;
  gap: 5rem;
  justify-content: center;
  align-items: center;
}

.swiftcontents {
  gap: 5rem;
}

.radar-feature {
  /* width: 200px; */
}

.humanReasources-employee-area {
  background: linear-gradient(
    180deg,
    #f5f9ff 0%,
    rgba(245, 249, 255, 0) 94.12%
  );
}

.employee-header h1 {
  font: 44px GT-bold !important;
}

.employee-header p {
  font: 19px GT-thin !important;
}

.employee-features {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  height: 470px;
}

.employee-feature {
  height: 100%;
  width: 100%;
  padding: 30px 20px 2rem 20px !important;
}

.employeeCardbg1 {
  height: 100%;
  background: #bcd2f6;
  border-radius: 8px;
}

.id-passimgs img {
  width: 550px;
}

.employeeCardbg2 {
  background: #bcebe7;
  border-radius: 8px;
  height: 100%;
}

.employeeCardbg3 {
  background: #f8c9d2;
  border-radius: 8px;
  height: 100%;
}

.employeeCardbg4 {
  background: #70deff;
  border-radius: 8px;
  height: 100%;
}

.employee-features h5 {
  font: 17px GT-medium !important;
  color: #003e51;
}

.radar-features,
.radar-feature h5,
.seems h5 {
  font: 18px GT-bold !important;
  color: #003e51;
}

.radar-feature p,
.seems p,
.employee-feature p {
  font: 16px GT-thin !important;
  line-height: 1.5rem !important;
  color: #003e51;
}

.radar-feature img {
  padding-bottom: 20px;
}

.feature-areas {
  background: #fff !important;
}

.employeeImg {
  height: 75px;
}

.seems-title {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* gap: 1rem; */
}

.seems-title .easy {
  width: 100%;
  display: block;
}

/* .easy {
  display: block;
} */

.easy {
  /* font-family: sans-serif;
  margin: 100px auto;
  text-align: center;
  font-size: 40px;
  max-width: 600px; */
  position: relative;

  z-index: 2;
}
.easy span h5 {
  margin-top: 8px;
}

.easy span p {
  margin-top: 3px;
}

.easy:before {
  content: "";
  display: block;
  width: 145px;
  height: 1px;
  background-color: #007da3 !important;
  position: absolute;
  left: 0;
  top: 110px;
  z-index: -2;
}
.easy:after {
  content: "";
  display: block;
  width: 145px;
  height: 1px;
  background-color: #007da3 !important;

  position: absolute;
  right: 0;
  top: 110px;
  z-index: -2;
}
.easy span {
  background: #fff;
}

.seems-title .second::before,
.seems-title .second::after {
  top: 26px !important;
}

.seems-title .easy .second {
  margin-bottom: -9rem;
}

.seems-title .easy.third::after,
.seems-title .easy.third::before {
  top: 86px !important;
}

/*******  random background ******/

.random-background-area {
  /* background: url("assets/Base.png") center center no-repeat; */
  transition: 0.3s;
  background-size: cover;
  color: #fff;
  min-height: 95vh;
  display: flex;
  align-items: center;
}

.random-body {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.random-body h1 {
  font: 50px GT-medium;
}

.random-body h5 {
  font: 18px GT-light !important;
  line-height: 2rem !important;
}

.countries-gap {
  gap: 5rem;
}

.prembly-gap {
  gap: 5rem;
}
/*******  end random background ******/

.watch-video {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #007da3;
  background: #efefef;
  z-index: 10;
  /* width: 100%; */
  height: 500px;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  margin-bottom: -20%;
}

.watch {
  display: flex;
  align-items: center;
  /* justify-content; */
  padding: 0 20px;
  width: 500px;
  height: 65px;
  border: 2px solid #003241;
  border-radius: 5px;
}
.watch i {
  font-size: 20px;
  margin-right: 20px;
}

.watch i,
.watch h1 {
  color: #003241;
}

.watch h1 {
  font: 21px GT-bold !important;
  padding-top: 10px;
}
.watch span {
  font: 20px Gt-light;
}

.how-radar-helps-area {
  background: #e9f0fc;
  padding: 5rem 0;
}

.app.light-mode .radar-hero-area h1 {
  font: 65px GT-bold;
}

.app.light-mode .radar-hero-area p {
  font: 19px GT-thin;
  line-height: 2rem !important;
  color: #fff;
}

.checking-price-btn {
  background: #37b7ab !important;
  color: #fff !important;
  outline: none;
  border: none;
  border-radius: 2px !important ;
  /* padding: 19px 25px !important; */
}

.app.light-mode .radar-hero-area p:first-child {
  border: 1px solid #fff;
  width: 150px;
  margin: 20px 0;
  color: #fff;
  padding: 10px 20px;
}

.app.light-mode iframe {
  height: 520;
}
.app.light-mode .reduce-fraud {
  background: #f4f5f7;
}
.app.light-mode .reduce-fraud .card {
  /* background-image: url("assets/card.png"); */
  background-repeat: no-repeat;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
  border-radius: 5px;
  background-color: #ffffff;
  min-height: 400px;
  display: flex !important;
  align-items: flex-end !important;
  padding: 130px 0px 10px 0px;
}

.app.light-mode .forms-hero-area {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.app.light-mode .forms-hero-area h1 {
  font: 50px GT-bold;
  /* line-height: ; */
}
.app.light-mode .forms-hero-area p {
  border: 1px solid #000;
  width: 200px;
  margin: 20px auto;
}
.app.light-mode .forms-hero-area h5 {
  font-family: GT-regular;
  line-height: 150%;
}
.app.light-mode .forms-explainer-area {
  background: #fff;
}
.app.light-mode .forms-explainer-area .explainer-div {
  background: #d9d9d9;
  position: relative;
  top: -15vh;
}

.privacy-hero {
  background: linear-gradient(
      rgba(215, 232, 255, 0.3),
      rgba(215, 232, 255, 0.3)
    ),
    url("https://res.cloudinary.com/dkssurk1s/image/upload/v1648716894/idPass-web-assets/about-us-hero_cak3se.png")
      center center no-repeat;
  background-size: cover;
  padding: 10rem 0rem;
  color: #000 !important;
}
.privacy-hero i {
  font-size: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #091e42;
  color: #091e42;
  margin: 50px auto 0px auto;
}
.privacy-gap {
  /* gap: 1rem; */
}
.info-policy p {
  font: 14px ST-lights;
  color: #000000;
  /* line-height: 1.7rem; */
}

.info-policy h4 {
  font: 15px ST-mediums;
  color: #000000;
  line-height: 5px;
  line-height: 1.7rem;
}
.privacy-boxs h5 {
  font: 14px ST-bolds;
  color: #000000;
}

.privacy-boxs h3 {
  font: 13px ST-regulars;
  color: #000000;
}

.decendant {
  padding-left: 10px;
  display: flex;
  gap: 10px;
}

.decendant p {
  font: 14px ST-lights;
  color: #000000;
  padding-top: 10px;
}

.outlines p {
  padding-top: 20px;
  font: 13px ST-lights;
  color: #000000;
}
.alpha-section > span {
  line-height: 1rem !important;
}
.alpha-section {
  padding-left: 0 !important;
}

.app.light-mode .footer-area {
  background: #000;
  background-size: cover;
  position: relative;
  color: #fff;
}

/*************** latest *****************/

.latest {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/company.png")
    center center no-repeat;
  background-size: cover;
  color: #fff;
  padding: 7rem 0;
}

.latest h1 {
  font: 48px ST-blacks;
}

.latest p {
  font-size: 18px;
  line-height: 1.5em;
  font-family: ST-lights !important;
}

.formcontainer {
  /* width: 100% !important; */
  gap: 0.5rem;
}

.container-fluids {
  padding: 5rem 5rem;
}

.form-group i {
  position: absolute;
}

.form-group {
  margin: 40px auto;
}

.formbox {
  width: 100% !important;
  height: 54px;
  background: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.formbox > input {
  flex: 1;
  width: 100% !important;
  height: 40px;
  color: #003e51;
  outline: none !important;
  font-size: 0.9rem;
  font-family: GT-light;
  padding-left: 10px;
  border: none;
  background: #fff;
}

.video-area video {
  border-radius: 15px;
  border: 1px solid #1633a3;
}

.video-area-dialogue video {
  border-radius: 15px;
  height: 500px !important;
  width: 100%;
  border: 1px solid #1633a3;
}

.email-btn {
  color: #ffff;
  background: #37b7ab;
  outline: none;
  border: none;
  width: 180px;
  height: 54px;
  cursor: pointer;
  font-family: GT-medium !important;
  font-size: 1.1rem;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.latestbutton {
  display: flex;
  gap: 0.4rem;
}

.icon {
  font-size: 15x;
  color: #183b56;
  margin-left: 10px !important;
}

/*************** latest end****************/

/******* Company tag********/
.about-us .about-us-heading h1 {
  font: 50px ST-blacks !important;
}

.about-us {
  background: #e9f0fc !important;
}

.about-us p {
  text-align: center;
}

.getInTouch {
  padding: 8rem 0 5rem 0 !important;
  background: #e9f0fc !important;
}

.join-us {
  padding: 8rem 0 5rem 0 !important;
  background: #e9f0fc !important;
}

.compliance-hero {
  background: #e9f0fc !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0 5rem 0 !important;
}

.compliance-header {
  padding-top: 50px;
}

.selectCompliance {
  position: relative;
}

.selectComTitle {
  position: absolute;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #f3f4f5;
  background-color: #f3f4f5;
  color: #101828;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.selectComTitle p {
  font: 14px GT-Medium !important;
  margin: 20px;
  color: #101828;
}

.selectCompliance .selectComTitle p:hover {
  color: #101828 !important;
  font: 13px GT-Medium !important;
}

.compliance-hero h1 {
  font: 64px ST-blacks !important;
  color: #003e51;
}

.compliance-hero p {
  font: 19px ST-regulars !important;
  color: #475467;
}

.first-certificate img {
  width: 150px;
  height: 150px;
}

.newcert img {
  width: 100%;
  height: 100%;
}

.newcert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 30px; */
}

.scanCard {
  background: #ffffff !important;
  width: 210px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanerQRCode {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scanerQRCode p {
  color: #ffffffd1;
  font-family: ST-lights;
}

.scanCard .worldQR {
  width: 80px;
  height: 80px;
}

.scanCard .QRcode {
  width: 100px;
  height: 100px;
  margin-right: 0;
}

.first-certificate h1 {
  font: 20px ST-bolds !important;
}

.first-certificate span h5 {
  font: 14px ST-bolds !important;
  margin-bottom: 0;
}
.first-certificate span {
  /* line-height: 20px; */
}

.first-certificate span small {
  font: 11px ST-regulars;
  color: #475467;
}

.filterMainModal .main-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  padding: 50px 10px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterMainModal .main-modal .close-modal {
  background-color: #fff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid #000;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.filterMainModal .main-modal-content {
  border-radius: 10px !important;
  padding: 20px;
  position: relative;
}

.filterMainModal .main-modal .card {
  width: 800px;
  margin-top: 100rem;
}

#main2 .card {
  margin-top: 2250rem;
}

.newcert2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 0px 10px 0px 10px; */
}

.newcert2 img {
  width: 100%;
  height: 100%;
}

.testimonial-area {
  padding-bottom: 4rem !important;
  padding-top: 9rem !important;
}

.about-us .about-us-heading p {
  font: 18px ST-lights !important;
  color: #5a7184;
  line-height: 2rem !important;
}

.video-view-box {
  margin-top: -180px;
  height: 450px;
  /* background: #8fb4f0; */
  border-radius: 8px;
  width: 100%;
}

.video-view-box img {
  width: 100%;
  height: 100%;
}
/* 
    {
      display: flex;
      justify-content: center;
    } */

.viewsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  margin-top: 3rem;
}

.allviews h5 {
  font: 35px ST-bolds !important;
}

.allviews p {
  color: #5a7184;
  font: 18px ST-lights !important;
}

.mission-content {
  row-gap: 14px;
}

.mission-content h1 {
  font: 36px ST-blacks !important;
  margin-bottom: 20px;
}

.mission-content p {
  font: 18px ST-lights !important;
  line-height: 1.7rem !important;
}

.mission-content {
  padding: 10px;
}

.ourmission-videos {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/company.png")
    center center no-repeat;
  color: #fff;
  border-radius: 16px;
  background-size: cover;
  padding: 40px;
}

.gapfornav {
  /* margin-left: 30px; */
}

.cores {
  background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/clo.png")
    no-repeat;
  background-size: cover;
  color: #fff;
  align-items: center;
}

.coreimg {
  height: 542px;
  width: 402px;
  /* margin-top: 20rem !important; */
  /* position: absolute; */
}

.coreimg img {
  height: 100%;
  width: 100%;
}

.coreValue h1 {
  font: 45px ST-blacks !important;
}

.coreValue p {
  font: 20px ST-lights !important;
}

.coreContent {
  gap: 5rem;
}

.core-list .core-items {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding-bottom: 1.5rem;
}

.core-list .core-items h5 {
  font: 18px ST-mediums !important;
}

.havefun {
  background: #e9f0fc;
}

.funHeader h1 {
  font: 48px ST-blacks !important;
}

.funHeader p {
  font: 19px ST-lights !important;
  line-height: 1.7rem !important;
  color: #000000;
}
.galleryfun {
  display: block;
}

.galleryfun .insta-img {
  padding-bottom: 1rem;
}

.media-header-content {
  margin-left: 160px;
}

.mentions .tes h5,
.past-mentions .tes h5 {
  padding-bottom: 20px;
  font: 18px ST-bolds;
}

.pastcard {
  display: flex;
  justify-content: center !important;
}

.mentions .newsContainer .newsCard img,
.past-mentions .newsCard img {
  height: 200px;
}

.funImgs {
  display: flex;
}

.funimgs {
  display: block;
}

.funimgs .insta-img {
  padding-bottom: 0.5rem;
}

.funimgs img {
  /* position: relative; */
}

.insta-img a:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s ease-in-out;
  transition: 0.5s ease;
  background: rgba(0, 0, 0, 0.295);
  cursor: pointer;
}

.insta-img {
  position: relative;
}

.first-blocks {
  display: block !important;
}

.becomeateam {
  color: #fff !important;
  background: #37b7ab !important;
  padding: 13px 25px 13px 25px !important;
  font-family: ST-regulars !important;
}

.becomeateam:hover,
.becomeateamblue:hover {
  background: #003241 !important;
}

.becomeateamblue {
  background: #4312c7 !important;
  padding: 13px 25px 13px 25px !important;
  color: #fff !important;
}

.becomeateampink {
  color: #fff !important;
  background: #0768dd !important;
  padding: 13px 25px 13px 25px !important;
}

.becomeateampink:hover {
  background: #0768dd !important;
}

.becomeateamblues {
  color: #fff !important;
  background: #ef5da8 !important;
  padding: 13px 25px 13px 25px !important;
}

.becomeateamblues:hover {
  background: #003241 !important;
}

/******career******/
.joinContent-box p {
  font: 18px ST-mediums !important;
  line-height: 2rem !important;
}

.join-us h1 {
  font: 50px ST-blacks !important;
  color: #101828 !important;
}

.joins {
  gap: 4rem;
}

.join-text p {
  font: 18px ST-mediums !important;
  line-height: 2rem !important;
}

.joinImgDiv .joinbox {
  height: 350px;
}

.seeroles {
  color: #fff !important;
  background: #37b7ab !important;
  padding: 12px 27px 12px 27px !important;
  width: 250px;
}

.seeroles:hover {
  /* background: #e9f0fc !important; */
  color: #8fb4f0 !important ;
}

/*** work at prembly ***/

.workatprembly h1 {
  font: 35px ST-bolds !important ;
  color: #101828 !important;
}

.benefits-items {
  display: flex;
  gap: 2.5rem;
}

.benefits-items h5 {
  font: 17px ST-mediums !important;
  color: #101828 !important;
}

.form-contents {
  padding-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefits-items p {
  font: 17px ST-regulars !important;
  line-height: 1.5rem !important;
  color: #475467 !important;
  margin-top: 20px;
}

.getinTouchHeader h1 {
  font: 62px GT-bold !important;
  line-height: 5.5rem !important;
}

.contact-message {
  /* background: #fff; */
  padding: 20px 0px 5rem 0px;
  justify-content: center;
  align-items: center;
}

.messages {
  width: 100%;
  position: relative;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages .danger {
  font: 20px;
  color: red;
  position: absolute;
  animation: buttons 0.3s linear;
  display: none;
  transition: 0.3s;
}

.messages .success {
  font: 20px;
  color: #37b7ab;
  position: absolute;
  animation: buttons 0.3s linear;
  /* display: none; */
}

@keyframes buttons {
  0% {
    transform: scale(0.1);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.meet-teams {
  background: url("./assets/light.svg") center center no-repeat;
  background-size: cover;
  padding: 5rem 0;
  height: 100%;
}

.meet-teams h1 {
  font-family: ST-bolds !important;
  font-size: 40px !important;
}

.meet-teams p {
  font-family: ST-regulars;
  font-size: 18px;
  margin: 15px 0 10px 0;
}

.join-uss {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
  padding: 1rem 0px 5rem 0px;
}

.teamsimgs {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.meetsCards .card {
  width: 100% !important ;
  min-height: 400px !important;
  border: 1px solid #a1aec5 !important;
  border-radius: 4px;
  background: #ffffff;
  border: none !important;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.05);
  padding: 15px;
  position: relative;
}

.meetsCards {
  width: 100% !important ;
  min-height: 400px !important;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  align-items: center !important;
  grid-gap: 40px;
}

.event-resvp p {
  font-family: ST-regulars;
  font-size: 18px;
  margin: 15px 0 10px 0;
  text-align: justify;
}

.frontcard {
  width: 100%;
  height: 100%;
  transition: transform 0.1s ease;
}

.newteams {
  width: 100% !important;
}

.frontcard img {
  display: block;
  /* max-width: 100%; */
  width: 100%;
  height: 230px;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
}

.front-title {
  padding: 20px 0;
}

.front-title h4 {
  color: #000000;
  font-size: 19px;
  font-family: ST-bolds;
}
.front-title p {
  color: #8193b1;
  font-size: 14px;
  font-family: ST-regulars;
}

.back-card {
  background: #003241;
  /* position: absolute; */
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  min-height: 450px;
  /* height: 100%; */
  width: 100%;
  border-radius: 8px;
  z-index: 2;
  padding: 15px;
  transform: rotateY(-180deg);
  transition: transform 0.1s ease;
}

.backflip {
  transform: rotateY(-180deg);
}

.back-card-header {
  gap: 1rem;
  transform: rotateY(-180deg);
}

.back-card-header h4 {
  font-size: 18px;
}

.flip {
  display: block;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.flip p {
  font-size: 12px;
}
.back-card img {
  width: 242px;
  height: 108px;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
}

.back-card .back-title {
  width: 100%;
  text-align: left;
}

.back-card .back-title p {
  font-size: 12px;
  font-family: ST-regulars;
  color: #ffffff;
}

.teamshandles {
  gap: 7px;
}

.teamshandles a {
  color: #003e51;
  font-family: ST-regulars;
  font-size: 14px;
  cursor: pointer;
}

.teamshandles a:hover {
  color: #003e51;
  border-bottom: 1px solid #003e51;
}

.flips {
  width: 26px;
  height: 26px;
  background: #01879fbc;
  border-radius: 24px;
}

.errorpage {
  background-color: #e7f5f4;
  min-height: 85vh;
  position: relative;
  z-index: 1;
}

.errorContent img {
  width: 230px;
  height: 230px;
  top: -135px;
  margin-left: -120px;
  position: absolute;
  z-index: -2;
}

.errorContent {
  display: block;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 90%;
  transform: translateY(150%);
  text-align: center;
}

.errorContent > .errorheader {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font: 100px ST-blacks;
}

.errorContent p {
  font: 16px ST-regulars !important;
  line-height: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.getinTouchHeader p {
  font: 21px ST-lights !important;
  line-height: 2rem !important;
}

.inputrows textarea {
  border: 1px solid #eaecf0;
  outline: none;
  background: #fff !important;
}

.inputrows input {
  border: 1px solid #eaecf0;
  outline: none;
  background: #fff !important;
}

.getInTouchForms textarea {
  font: 16px ST-regulars !important;
  padding: 20px;
  width: 100%;
}

.getInTouchForms input {
  font: 14px GT-regular !important;
}

.getInTouchForms input[type="email"] {
  padding: 17px 15px;
  width: 100%;
  border: 1px solid #eaecf0;
  outline: none;
  background: #fff !important;
}

.getInTouchForms input[type="text"] {
  padding: 17px 15px;
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 6px;
  outline: none;
  background: #fff !important;
}

/******* Company tag end********/

.customers-perspective-area .card {
  min-height: 500px;
  box-shadow: 0px 10px 18px 0px #091e4226;
}
.customers-perspective-area .card .card-header {
  background: #091e42 !important;
  padding: 20px 10px;
}
.customers-perspective-area .card-header i {
  font-size: 70px;
  color: #fff;
}
.customers-perspective-area .card-footer {
  display: flex;
  background: #fff !important;
  border: none !important;
  padding: 100px 0px 20px 0px;
  margin-top: 50px;
  align-items: center !important;
}
.customers-perspective-area .card-footer img {
  width: 60px;
  margin-right: 10px;
}
.customers-perspective-area .card-footer p {
  padding: 0;
  margin: 0;
}
.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
  opacity: 1 !important;
}
.react-3d-carousel .slider-container .slider-content {
  width: 90% !important;
}
.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-left
  div,
.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-right
  div {
  background-color: #fff;
  color: #000;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0px;
  border-radius: 50%;
  font-size: 20px !important;
}
/***************testimonial area*****************/
.testimonial-area h1 {
  font: 65px ST-blacks !important;
}

.testimonial-area .testimonialimgcontainer {
  width: 500px;
  height: 500px;
}

.testimonialImg:before {
  content: "";
  width: 100%;
  height: 500px;
  position: absolute;
  border: solid 1px #000000;
  top: -20px;
  right: -20px;
  border-radius: 15px;
}

.testimonialImg {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 24px;
}

.testimonial-area p {
  font: 17px ST-lights !important;
  color: #5a7184 !important;
}

.testimonial-content {
  padding-top: 2rem;
  gap: 3rem;
}

.testimonial-content i {
  font-size: 70px;
  color: #ebebeb;
}

.testimonial-content p {
  margin-top: 3rem;
  font: 18px GT-light !important;
  line-height: 2rem !important;
  color: #000000 !important;
}

.users-testimony {
  margin-top: 6rem;
}

.testimonialImg {
  padding-left: 5rem;
}

.cant-find {
  width: 100%;
  padding: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cant-finds {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cant-find h3 {
  font: 22px ST-bolds;
  color: #fff;
}

.btn-sales {
  width: 200px;
  height: 50px;
  color: #fff !important;
  background: #37b7ab !important;
  border-radius: 4px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
}

.btn-sales1 {
  height: 50px;
  color: #fff !important;
  border: 1px solid #37b7ab !important;
  border-radius: 4px !important;
  background: #fff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  font-family: ST-bolds;
  font-size: 15px;
  color: #37b7ab !important ;
  padding: 30px 0px;
}

.btn-sales1.signin-sales {
  padding: 0px 40px !important;
}

.btn-sales2.signin-sales {
  padding: 0px 40px !important;
}

.btn-sales1:hover {
  color: #fff !important;
  background: #183b56 !important;
}

.btn-sales2 {
  height: 50px;
  color: #fff !important;
  background: #37b7ab !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  padding: 30px 0px;
}

.btn-sales2:hover {
  background: #183b56 !important;
}

.industies-img img {
  height: 440px;
}

/**** industries ****/
.sectors {
  padding: 3rem 7rem !important;
}

.sectors h1 {
  color: #183b56;
  font: 43px ST-blacks !important;
  margin-top: 30px;
}

.quickhelp p {
  line-height: 7.5rem !important;
}

.quickhelp h1 {
  font: 45px ST-blacks !important;
  color: #4312c7 !important;
}

.quickhelps h1 {
  font: 45px ST-blacks !important;
  color: #183b56 !important;
}

.quickhelp p {
  font: 18px ST-lights !important;
  line-height: 2rem !important;
  color: #000000;
}

/********id-form*******/
.typical-title h1 {
  padding-bottom: 1rem;
  font: 42px ST-blacks !important;
  color: #003e51;
}

.typical-title p {
  font: 17px ST-lights !important;
  color: #003e51;
}

.form-group {
  margin-bottom: 20px;
}

.footer-area .container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://prembly-assets.s3.us-east-2.amazonaws.com/footer-img.png")
      center center no-repeat;
  background-size: cover;
}
.footer-area .container .row {
  /* padding-bottom: 50px; */
}

.footer-area .follow-us {
  padding-left: 5rem;
}

.footer-area h4 {
  font: 19px ST-mediums;
}

.footer-area img {
  font-size: 35px;
  margin-right: 10px;
}
.footer-area a,
.footer-area .link {
  color: #fff !important;
}
.footer-area a p {
  font-size: large;
}

.place p {
  font-size: large;
  font-family: ST-lights !important;
}

/* .footer-area a img:hover{
  color: #07485e !important;
} */

.footer-area a p:hover {
  color: #37b7ab;
}

.card-info {
  margin-left: 30px;
}

/* partnership page section */
.partnership-hero-area .partnership-hero-card {
  background: url("../src/assets/partnership-hero-bg.png") center center
    no-repeat;
  background-size: cover;
  padding: 5rem 1rem;
}
.partners-explore-area p {
  min-height: 120px;
}
.partnership-partner-area {
  position: relative;
}
.partnership-partner-area::before {
  position: absolute;
  content: "";
  background: url("../src/assets/partnership-deco.png") center center no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  right: 50%;
  /* top: 50; */
  width: 40%;
  height: 50%;
}
.partnership-join-area .partnership-join-card {
  background: url("../src/assets/join-bg.png") center center no-repeat;
  background-size: cover;
  padding: 5rem 1rem;
}

/* partnership page section ends here */

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@media screen and (max-width: 1024px) {
  .verifyallapplicant {
    display: flex;
    justify-content: center !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .newsContainer,
  .lastestNews {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .connects {
    padding: 0 0rem;
  }

  .media-header-content {
    margin-left: 0px;
  }

  .errorpage {
    min-height: 80vh;
  }

  .errorContent {
    top: 0%;
    transform: translateY(155%);
  }

  .errorContent img {
    height: 180px;
    top: -120px;
    width: 180px;
    margin-left: -90px;
  }

  .errorContent .errorheader {
    font-size: 70px;
  }

  .errorContent p {
    font: 14px ST-regulars !important;
  }

  #main2 .card {
    margin-top: 2262rem;
  }

  .filterMainModal .main-modal .card {
    margin-top: 110rem;
    /* width: 90%; */
  }
  .container-fluids {
    padding: 5rem 0rem;
  }

  .verifyallapplicant {
    padding: 3rem !important;
    gap: 4rem;
  }

  .sector-area h1 {
    font-size: 38px !important;
  }
  .countries-gap {
    gap: 3rem;
  }

  .sector-area h5 {
    font-size: 17px !important;
  }
  .verify-applicants {
    width: 380px;
  }

  .verify-applicants img {
    width: 380px;
    border-radius: 10px !important;
  }

  .newLeft {
    display: none;
  }

  .btn-sales1.signin-sales {
    padding: 10px 13px !important;
    font-size: 11px;
  }

  .btn-sales2.signin-sales {
    padding: 10px 13px !important;
    font-size: 11px;
  }

  .longimg {
    display: none;
  }

  .code-sec {
    display: block;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .imgleft {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-right {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .identitypassHero h1 {
    font: 36px ST-blacks !important;
  }
  .identitypassHero h5 {
    font: 16px ST-lights !important;
  }

  .allviews h5 {
    font: 22px ST-bolds !important;
  }

  .allviews p {
    color: #5a7184;
    font: 18px ST-lights !important;
  }

  .navigateBox {
    gap: 1rem;
  }

  .prembly-gap {
    gap: 3rem;
  }

  .code-sec {
  }

  .hero-section,
  .container-fluid,
  .eventHero .container-fluid {
    padding: 2rem !important;
  }

  .meetsCards {
    grid-template-columns: repeat(3, 1fr);
  }

  .navbar-expand-lg .navbar-nav {
    padding-left: 1rem !important;
  }

  .navbar-area .nav-item {
    margin-right: 10px;
    /* font-size: 10px; */
  }

  .navbar-options.products-optionss {
    margin-right: -25px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font: 14px ST-regulars;
  }
}

@media screen and (max-width: 992px) {
  .ps-lg-5 {
    padding-left: 1rem !important;
  }

  .newcert2 img {
    width: 50%;
    height: 50%;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    padding-left: 0rem !important;
  }
}

@media screen and (max-width: 946px) {
  .verifyallapplicant {
    display: flex !important;
    flex-direction: row;
  }
  .media-header-content {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .newLeft {
    display: none;
  }

  .media-header-content {
    margin-left: 0px;
  }
  .errorpage {
    min-height: 80vh;
  }

  .errorContent {
    top: 0%;
    transform: translateY(160%);
  }

  .connects {
    padding: 0 0rem;
  }

  .errorContent img {
    height: 160px;
    top: -105px;
    width: 160px;
    margin-left: -80px;
  }

  .errorContent .errorheader {
    font-size: 60px;
  }

  .errorContent p {
    font: 14px ST-regulars !important;
  }

  .video-area-dialogue video {
    height: 300px !important;
  }
  .privacy-gap {
    gap: 3rem;
  }

  .event-titles {
    max-width: 65%;
  }

  .newsContainer,
  .lastestNews {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    width: 100% !important;
    justify-content: center;
    gap: 2rem;
  }

  .newsCard .card {
    width: 300px;
  }

  #footer-content-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .cookie-btn {
    flex-direction: column;
  }

  .ant-modal-content {
    width: 100% !important;
  }

  .newcert2 img {
    width: 100%;
    height: 100%;
  }

  #main2 .card {
    margin-top: 1818rem;
  }

  .filterMainModal .main-modal .card {
    margin-top: 80rem;
    width: 90%;
  }

  .cert2 .newFilter.main-modal .card {
    margin-top: 10rem !important;
    width: 90%;
  }

  .certificates {
    gap: 3rem;
  }

  .compliance-hero h1 {
    font: 45px ST-blacks !important;
  }

  .first-certificate .btn-green1 {
    font-size: 14px !important;
  }

  .verifyallapplicant {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .feedback-prembly-area {
    background-size: cover !important;
    padding: 2rem 0;
  }

  .news-header {
    width: 100%;
    /* height: 0px; */
  }

  .card-info {
    margin-left: 0px;
  }

  .verify-applicant {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
  }

  .swift-text {
    text-align: center;
  }

  /* .integrate{
  margin-top: 5rem !important;
  } */

  .verify-applicants {
    margin-top: 3rem;
  }

  .verify-applicants img {
    width: 450px;
  }

  .verify-applicants {
    width: 450px;
  }

  .navbar-nav a .btn-sales1 {
    padding: 10px !important;
  }

  .code-countries {
    display: block;
    justify-content: center !important;
    align-items: center;
  }

  .container,
  .container-fluid {
    padding: 1rem;
  }

  .about-us .about-us-heading h1 {
    font: 35px ST-blacks !important;
  }

  .about-us .about-us-heading p {
    font: 16px ST-lights !important;
    text-align: justify;
  }

  .video-view-box {
    margin-top: -140px;
    height: 300px;
  }

  .about-us {
    padding-bottom: 3rem;
  }

  .viewsContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
  }

  .allviews h5 {
    font: 22px ST-bolds !important;
  }

  .allviews p {
    color: #5a7184;
    font: 18px ST-lights !important;
  }

  .navigateBox {
    flex-direction: column;
  }

  .newsContainer {
    /* grid-template-columns: repeat(2, 1fr); */
  }

  .pressContainer {
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .logosContainer {
    grid-template-columns: repeat(2, 2fr);
    gap: 1rem;
  }

  .cant-find {
    flex-direction: column;
  }

  #columforbg {
    flex-direction: column-reverse;
  }

  .longimg img {
    display: none;
  }

  .hero-section,
  .container-fluid {
    padding: 2rem !important;
  }

  .cant-finds {
    gap: 2rem;
  }

  .cant-finds {
    gap: 2rem;
  }

  .meetsCards {
    grid-template-columns: repeat(2, 1fr);
  }

  .mentions .tes h5 {
    text-align: center;
    margin-left: 0rem;
    padding-bottom: 20px;
  }

  .navbar-options.products-optionss {
    margin-right: -110px;
  }

  /* .cookie-btn{
    
    flex-direction: column !important;
  }
  .cookie-btn.btn{
    width: 100% !important;
  } */
  /* partnership page */

  .partners-explore-area p {
    min-height: unset;
  }
  .partners-explore-area {
    padding-top: 25px;
  }
  .partners-explore-area p {
    /* margin-bottom: 0px; */
    padding-bottom: 0px;
  }
}

/* less than or equals to 600px  */
@media screen and (max-width: 600px) {
  .app.light-mode .hero-section::after {
    width: 200px;
    height: 150px;
  }
  /* .tracking-text {
    font-size: 14px;
    text-align: center;
    line-height: 20px;

  } */

  .media-header-content {
    margin-left: 0px;
  }

  .errorpage {
    min-height: 70vh;
  }

  .errorContent {
    top: 50%;
    transform: translateY(120%);
  }

  .errorContent img {
    height: 120px;
    top: -80px;
    width: 120px;
    margin-left: -60px;
  }

  .errorContent .errorheader {
    font-size: 50px;
  }

  .eventHero {
    padding-left: 0rem;
  }

  .errorContent p {
    font: 14px ST-regulars !important;
  }

  .newsContainer,
  .lastestNews {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .newsCard .card {
    width: 270px;
  }

  .connects {
    padding: 0 0rem;
  }

  .event-titles {
    max-width: 100%;
  }

  .event-resvp P {
    text-align: justify;
  }
  .dialogue-header h1 {
    font-size: 40px !important;
  }

  :root {
    --marquee-width: 100vw;
    --marquee-height: 16vh;
    --marquee-elements-displayed: 3;
  }

  .marquee-content li img {
    width: 1000px;
  }

  .video-area-dialogue video {
    height: 250px !important;
  }

  .marquee:before,
  .marquee:after {
    width: 5rem;
  }

  .ant-modal-content {
    width: 100% !important;
  }

  #main2 .card {
    margin-top: 844rem;
  }

  .filterMainModal .main-modal .card {
    margin-top: 24rem;
  }

  .certificates {
    flex-direction: column;
    gap: 1.5rem;
  }

  .first-certificate .btn-green1 {
    font-size: 13px !important;
  }
  .app.light-mode .navbar-area .navbar {
    margin: 5px;
  }
  .clock p {
    font: 30px ST-mediums;
  }

  .eventLocation {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
  }

  .mentions .tes h5 {
    text-align: center;
    margin-left: 0rem;
    padding-bottom: 20px;
  }

  .hero-section h1,
  .identitypassHero h1,
  .verifyapplicantstoday h1,
  .verifyapplicantstodays h1,
  .quickhelp h1,
  .quickhelps h1,
  .sectors h1,
  .verifyapplicantstodaygreen h1 {
    font-size: 36px !important;
  }

  .hero-section h5,
  .identitypassHero h5,
  .eventHero h5 {
    font-size: 18px !important;
  }

  .app.light-mode .forms-hero-area h1 {
    font: 30px ST-bolds;
  }
  .app.light-mode .pageTab li h5 {
    font-size: 15px;
  }

  .formcontainer {
    display: block;
    flex-direction: column;
    gap: 1.2rem;
  }

  .formcontainer .formbox {
    border-radius: 10px;
  }

  .formcontainer button {
    border-radius: 6px;
  }

  .container,
  .container-fluid,
  .container-fluid1 .how-to-start-areas .container,
  .sectors {
    padding: 1rem 1rem !important;
  }

  .container-fluid1 {
    margin: 0rem 0rem;
  }

  .countriesFlag img {
    width: 250px;
  }

  .prembly-ecosystem-area h1,
  .prembly-ecosystem-area p {
    text-align: left !important;
  }

  .quickhelps {
    text-align: left;
    padding: 1rem;
  }

  .authentic {
    text-align: left !important;
  }

  .imgleft {
    display: none;
  }

  .disables img {
    display: none !important;
  }

  .verifyallapplicant {
    gap: 1rem;
    padding: 3rem 0.5rem !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .newLeft {
    display: block;
  }

  .how-to-start-areas .inactive h5 {
    font-size: 18px;
  }

  .btn-green1,
  .btn-blue,
  .btn-pink,
  .btn-blues {
    padding: 15px !important;
    font-size: 17px !important;
    font-family: St-regular !important;
  }
  .btn-blacks,
  .btn-blacks i {
    font-size: 14px !important;
  }

  .verify-applicant {
    display: flex;
    justify-content: flex-start;
  }

  .verify-applicants {
    /* display:flex !important; */
    width: 290px;
  }

  .verify-applicants img {
    width: 290px;
  }

  #columforbg {
    flex-direction: column-reverse;
  }

  .wrapForm {
    /* display: flex; */
    gap: 1rem;
  }
  #get-it-blues {
    background: #f8b !important;
    padding: 3rem 0;
  }

  .get-forms {
    padding: 0rem 0;
  }

  .get-started-area .brown {
    height: 330px;
  }

  .get-ready {
    margin-left: -4rem !important;
  }

  .email-btn {
    width: 100%;
  }

  .longimg {
    margin-top: 18rem !important;
    display: none;
  }

  .card-info {
    margin-left: 5px;
  }

  .get-started-area {
    margin-top: 0rem;
  }

  .navbar-options.products-options {
    left: 0;
    min-width: 320px;
  }

  .navbar-options.products-option {
    left: 0;
    min-width: 320px;
  }

  .navbar-options.products-optionss {
    margin-right: -110px;
  }
  .industry-nav {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .about-us .about-us-heading h1 {
    font: 35px ST-blacks !important;
  }

  .about-us .about-us-heading p {
    font: 16px ST-lights !important;
    text-align: justify;
  }

  .video-view-box {
    margin-top: -130px;
    height: 200px;
  }

  .about-us {
    padding-bottom: 3rem;
  }

  .viewsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }

  .allviews h5 {
    font: 18px ST-bolds !important;
  }

  .allviews p {
    color: #5a7184;
    font: 14px ST-lights !important;
  }

  .mission-content {
    padding: 10px;
  }

  .ourmission-videos {
    padding: 10px;
  }

  .coreimg {
    margin-top: -6rem !important;
  }

  .funImgs {
    display: block;
  }

  .searchelp i {
    font-size: 18px;
    color: #777;
  }

  .containerPress {
    padding: 5rem 1rem 0 1rem;
  }

  .newsContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .pressContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }

  .logosContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .formbox {
    border-radius: 5px;
  }

  .latestbutton {
    flex-direction: column;
  }

  .eco-headings h1 {
    font-size: 27px !important;
  }

  .prembly-ecosystem-area h1 {
    font-size: 38px !important;
  }

  .navigateBox {
    flex-direction: column;
  }
  .feedback-prembly-area {
    background-size: contain;
  }

  .gapfornav {
    margin-left: 0px;
  }

  .logosimg img {
    width: 70px;
    font-weight: b;
  }

  .logosimgs img {
    width: 100px;
  }

  #arms img {
    width: 70px !important;
  }

  .cant-find {
    flex-direction: column;
    gap: 3rem;
  }
  .feedback-prembly-area {
    background-size: cover !important;
    padding: 2rem 0;
  }

  .loved-by-section h4 {
    text-align: center;
  }

  .custom h1 {
    font: 40px ST-blacks !important;
  }

  .card-info {
    margin-left: 0px;
  }
  .hero-section,
  .container-fluid {
    padding: 0.3rem !important;
  }

  .radar-hero-area .graphHeader h1 {
    font-size: 36px !important;
  }

  .cant-finds {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .firstlayers h1 {
    font: 30px GT-bold !important;
    color: #fff;
  }

  .swift-text {
    text-align: left;
  }

  .container-fluids {
    padding: 1rem 0rem;
  }

  .scanerQRCode p {
    font-size: 14px;
  }

  .footer-contents {
    flex-direction: column;
    justify-content: left;
  }

  .privacy-gap {
    gap: 3rem;
  }

  .meetsCards {
    grid-template-columns: repeat(1, 1fr);
  }
  .rsvp-section p {
    text-align: justify;
  }

  .cookie-btn {
    flex-direction: column;
  }
  .cookie-btn.btn {
    width: 100%;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-modal .ant-modal-content {
    width: auto !important;
    max-width: auto !important;
  }
}

@media screen and (max-width: 400px) {
  #main2 .card {
    margin-top: 700rem;
  }
}

.storyText {
  font-family: General Sans !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 32px !important ;
  text-align: center !important;
  max-width: 100% !important;
}
.custom-grey-progress-bar .progress-bar {
  background-color: #6c757d !important; /* Grey color for the progress bar itself */
}
