
.app {
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column; 
  padding:41px 0;
   background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/longbg.svg")
    center center no-repeat !important;
  align-items: center !important;
  background-size: cover !important;


}
.header-logo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}
.left-section {

    width: 100%; 
    margin: 0 auto; 
    text-align: center;
    margin-top: -80px !important;
    /* background: url("https://prembly-assets.s3.us-east-2.amazonaws.com/longbg.svg")
    center center no-repeat !important;
  align-items: center !important;
  background-size: cover !important; */

  
}

.compliance {
  margin-top: 2%;
  font-size: 3rem;
  font-weight: 600; 
  line-height: 1.2; 
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #003e51;
  font-family: ST-blacks !important;
  font-weight: bold;
  font-size: 46px !important;
 
}

/* .compliance .middle-word {
  margin: 0.5rem 0;
} */

.customers-enjoy {
  font-family: ST-mediums;
  font-size: 1.5rem; 
  font-weight: 500; 
  line-height: 1.8rem !important;
  color: rgba(53, 53, 53, 1);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0 auto; 
  max-width: 65%; 
}

.side-by-side {
  display: flex;
  justify-content: center; 
  align-items: center; 
  gap: 3rem; 
  margin-top: 4%;

}

.offers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.boxss {
  display: flex;
  background-color: #cfe6d5 !important;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: rgba(11, 10, 10, 1);
}

.boxss2 {
  display: flex;
  background-color: #e1deed !important;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: rgba(11, 10, 10, 1);
}

.submit-btn{
  font-family: var(--bs-btn-font-family); 
  background-color: #37b7ab !important;
  
}
.new{
padding: 10px 0;
}

.off-all-prembly{
font-family: ST-mediums !important;
}
@media screen and (max-width: 991px) {
  .left-section {
    max-width: 90%; 
  }
}

@media screen and (max-width: 600px) {
  .left-section {
    max-width: 90%; 
   
  }
  .app{
    padding: 20px 0;
  }
}

@media screen and (max-width: 500px) {
  .side-by-side {
    flex-direction: column;
    gap: 1rem; 
  }

  .boxss,
  .boxss2 {
    width: 85%; 
    justify-content: center;
  }
}
